import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CustomContainer } from "../../utils/customContainers";
import { useUserPreferences } from "../../contexts/UserPreferences";
import { Col, Container, Row, Spinner } from "react-bootstrap";


export const CustomContent = () => {
    const { postHash } = useParams();
    const { preferences } = useUserPreferences();
    const PostHashHex = postHash || 'fcc51e4a0d094f41151e5386183c5b98ebd276f403a6af6eae4655ba328c43ee'; // '0c15c1f029c5bff40a3b8fe873d01fb26f365ac8bc053dd9871ed340df623f32';
  
    return (
      <>
        <Container id="subNav">
          <Row className="text-center py-5">
            <h2 className="lh-1">
              <span className="text-info fw-bold"><i className="bi bi-bounding-box me-2"></i>Custom Content</span> 
            </h2>
            <p className="lead">Use a custom content model for advanced content</p>
            <p>Q&As, ticketing systems, bulletin boards and catalogues are examples of what you can create.</p>
            <p>These can also be added as custom tabs in your profile.</p>
          </Row>
        </Container>
        <Container>
            <CustomContainer key={`create`} />  
        </Container>
        <Container>
            <h1>Example</h1>
            <CustomContainer postHash={PostHashHex} />  
        </Container>
        <Container>
          <div className='row' key={`items`}>
            <Col xs={6}>
              <p>To do:</p>
              <ul>
                <li>Moderator config</li>
                <li>Moderation checks</li>
                <li>Thread Replies (and comments, where nested)</li>
                <li>Categories Above Forums? or take first layer as category</li>
                <li>Forum stats</li>
                <li>Sticky</li>
                <li>Member list? (req-mod-member)</li>
                <li></li>
              </ul>
            </Col>
            <Col xs={6}></Col>
          </div>
        </Container>
      </>
    );
  };