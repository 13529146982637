import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { DeSoIdentityContext } from "react-deso-protocol";
import { AccessGroupsContext } from "../contexts/AccessGroups";
import { useUserPreferences } from "../contexts/UserPreferences";
import { ContentForm } from './ContentForm';
import { ContentResults } from './ContentResults';
import { AppDataContext } from '../contexts/appData';

export const ContentBar = ({search, profile, rootLocation, setActiveTab, activeTab, customisation, userList, additionalOptions, viewTopic }) => {
  const {currentUser, alternateUsers} = useContext(DeSoIdentityContext);
  const {accessGroups} = useContext(AccessGroupsContext);
  const {appData} = useContext(AppDataContext);
  const {preferences} = useUserPreferences();
  const [searchTriggered, setSearchTriggered] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [topic, setTopic] = useState(viewTopic || null);

  const [showSearch, setShowSearch] = useState((profile && profile.username) ? false : search);

  const {querySearch, queryOptions} = useParams();

  console.log("SEARCH - querySearch, queryOptions:",querySearch, queryOptions);

  const defaultFeedType = 'posts';

  const determineFeedType = () => {
      if (activeTab === 'home' || activeTab === 'stats') {
          return activeTab;
      }

      if (activeTab === 'welcome') {
        return defaultFeedType;
      }
      if (activeTab !== '') {
          return activeTab;
      }

      if (customisation?.ExtraData?.defaultTab) {
          return customisation.ExtraData.defaultTab;
      }

      return defaultFeedType;
  };

  const [feedType, setFeedTypeState] = useState(determineFeedType());
  const [view, setView] = useState('list');

  const setFeedType = (type) => {
    setFeedTypeState(null);
    setFeedTypeState(type);

  };
  
  //console.log("[ContentBar.jsx] Search, showSearch, activeTab, Profile, rootLocation, Customisation",search,showSearch,activeTab,profile,rootLocation,customisation);

  //console.log("[ContentBar.jsx]",activeTab,feedType);

  const defaultValues = {
    orderBy: 'TIMESTAMP_DESC',
    isFollowing:
      profile || userList
        ? null
        : currentUser && currentUser?.PublicKeysBase58CheckFollowedByUser && currentUser?.PublicKeysBase58CheckFollowedByUser?.length !== 0
        ? preferences?.feedPreferences?.isFollowing === true
        : null,
    isHolder: 
      profile || userList
        ? null
        : currentUser && appData?.coinHolders?.Hodlers && appData?.coinHolders?.Hodlers?.length !== 0
        ? preferences?.feedPreferences?.isHolder === true
        : null,
    isHolding:
      profile || userList
        ? null
        : currentUser && currentUser?.UsersYouHODL && currentUser?.UsersYouHODL?.length !== 0
        ? preferences?.feedPreferences?.isHolding === true
        : null,
    poster: '', 
    publicKey: '',
    startDate: null,
    endDate: null,
    imageUrls: null,
    videoUrls: null,
    embedVideoUrl: null,
    isPinned: null,
    isFrozen: null,
    parentPostHash: null,
    parentPoster: null,
    isQuotedRepost: null,
    repostedPostHash: false,
    isNft: null,
    isHidden: false,
    hasUnlockable: null,
    numNftCopies: null,
    filterNumNftCopiesForSale: null,
    diamondsExist: null,
    repostedPostExists: null,
    repliesExist: null,
    nftBidsExist: null,
    blog: null,
    node: [],
    search: '',
    mentions: [],
    view: 'list',
    topic: viewTopic || null,
  };
  
  const [defaultTabValues, setDefaultTabValues] = useState({
    home: {},
    stats: {},
    images: {
      ...defaultValues,  // Start with the default values
      search: '',
      imageUrls: true,   // Override specific values for this tab
      view: 'grid',      // Specify the view setting for this tab
      posterPublicKey: userList ? userList : [],
    },
    video: {
      ...defaultValues,
      search: '',
      videoUrls: true,
      view: 'media',
      posterPublicKey: userList ? userList : [],
    },
    embed: {
      ...defaultValues,
      search: '',
      embedVideoUrl: true,
      view: 'media',
      posterPublicKey: userList ? userList : [],
    },
    blog: {
      ...defaultValues,
      search: '',
      blog: true,
      posterPublicKey: userList ? userList : [],
    },
    subscription: {
      ...defaultValues,
      search: '',
      subscription: true,
      posterPublicKey: userList ? userList : [],
    },
    discussions: {
      ...defaultValues,
      parentPoster: true,
      search: '',
      parentPostHash: true,
      posterPublicKey: userList ? userList : [],
    },
    posts: {
      ...defaultValues,
      parentPostHash: false,
      search: '',
      posterPublicKey: userList ? userList : [],
    },
    all: {
      ...defaultValues,
      search: '',
      repostedPostHash: null,
      posterPublicKey: userList ? userList : [],
    },
    default: {
      ...defaultValues,
      search: '',
      posterPublicKey: userList ? userList : [],
    },
  });

  const [formData, setFormData] = useState(defaultTabValues[feedType]);


  useEffect(() => {
    if(viewTopic) {
      setTopic(viewTopic);
      setFormData((prevFormData) => ({
        ...prevFormData,
        topic: viewTopic,
      }));
    } else {
      setTopic(null);
      setFormData((prevFormData) => ({
        ...prevFormData,
        topic: null,
      }));
    }
  }, []);

  useEffect(() => {
    if(viewTopic) {
      setTopic(viewTopic);
      setFormData((prevFormData) => ({
        ...prevFormData,
        topic: viewTopic,
      }));
    } else {
      setTopic(null);
      setFormData((prevFormData) => ({
        ...prevFormData,
        topic: null,
      }));
    }
  }, [viewTopic]);

  useEffect(() => {
    // Parse options and search term from URL params when component mounts
    console.log("[Search] Onmount, Formdata default:", formData);
    console.log("[Search] Onmount, querySearch, queryOptions:", querySearch, queryOptions);
  
    if (querySearch || queryOptions) {
      let searchQuery = querySearch ? querySearch : '';
      let searchOptions = queryOptions ? queryOptions : null;
  
      setFormData((prevFormData) => {
        // Create a new copy of prevFormData
        const newFormData = { ...prevFormData };
  
        // Update the search field with the decoded querySearch value
        newFormData.search = decodeURIComponent(searchQuery);
  
        // Parse and update the options
        const parsedOptions = parseOptions(searchOptions);
        for (const key in parsedOptions) {
          // Only update if the option exists and is not null or empty
          if (parsedOptions.hasOwnProperty(key) && parsedOptions[key]) {
            newFormData[key] = parsedOptions[key];
          }
        }
  
        console.log("[Search] Onmount, query detected:", newFormData);
        return newFormData;
      });
      setSearchTriggered(true);
    }
  }, [querySearch, queryOptions]);
  
  
  /*
  useEffect(() => {
    if(!searchTriggered && !showSearch) {
      setSearchTriggered(true);
    }
  }, [feedType]);
  */

  useEffect(() => {
    if (typeof setActiveTab === 'function') {
        setActiveTab(feedType);
        navigate(`${rootLocation}/${feedType}/`);
    }
  
    if(feedType === 'home' || feedType === 'stats') {
      //console.log("[search.jsx] Is a Profile Tab not a Feed - return");
      setSearchTriggered(false);
      setShowSearch(false);
      return;
    }
  
    //console.log("[search.jsx] Checking for custom feed...");
    
    let newFormData = defaultTabValues[feedType] || defaultTabValues.default;
    let viewSetting = newFormData.view || 'list'; // Default to 'list' if not specified
  
    if (profile && customisation?.ExtraData?.tabs?.[feedType]) {
      //console.log("[Search] This is a custom user feed:", feedType, customisation.ExtraData.tabs[feedType]);
      if(customisation.ExtraData.tabs[feedType].view) { 
        viewSetting = customisation.ExtraData.tabs[feedType].view;
      }
      newFormData = {
        ...newFormData,  // Spread the existing default values
        ...customisation.ExtraData.tabs[feedType].filter  // Overwrite with any keys from the custom filter
      };
    }
    setView(viewSetting);
    setFormData(newFormData);
  
    if(!showSearch) {
      setSearchTriggered(true);
    }
  }, [feedType]);

  const parseOptions = (optionsString) => {
    const options = {};
    if (optionsString) {
      optionsString.split(',').forEach(option => {
        const [key, value] = option.split(':');
        // Convert 'true' and 'false' strings to boolean values
        // Treat null and empty strings as null
        options[key] = value === 'true' ? true : value === 'false' ? false : value === null ? null : value;
      });
    }
    return options;
  };
  

  const formatOptions = (options, defaultValues) => {
    return Object.entries(options)
      .filter(([key, value]) => {
        // Exclude null values, empty strings, and empty arrays
        return value !== null && value !== '' && !(Array.isArray(value) && value.length === 0) &&
          // Include only if the value is different from the default value
          (!defaultValues || !defaultValues.hasOwnProperty(key) || defaultValues[key] !== value) &&
          // Exclude the key "search"
          key !== 'search';
      })
      .map(([key, value]) => `${encodeURIComponent(key)}:${encodeURIComponent(value)}`)
      .join(',');
  };
  

  const handleSearch = (data) => {
    //console.log("[Search.jsx] handleSearch()", data, searchTriggered);

    let url = `/`
    //console.log("ROOT LOCATION: ",rootLocation);
    if(profile) {
      url += `u/${profile.Username}/${activeTab}/`;
    } else if (rootLocation && typeof rootLocation === 'string' && rootLocation.trim() !== '') { url = `/${rootLocation}/`}

    if(search) {
      url += `search/`;
      const optionsString = formatOptions(data, formData);
      if(data.search && data.search !== '') { 
        url += encodeURIComponent(data.search);
        if(optionsString !== '') { url += `/`+optionsString; }
       }
    }
    setFormData(data);
    setSearchTriggered(true);
    navigate(url);
  };

  const handleReset = () => {
    setSearchTriggered(false); // Reset search trigger
    setFormData(defaultTabValues[view]); // Clear form data
  };
  //console.log("[ContentBar.jsx] TOPIC: ",viewTopic,formData);
  //className={`container-fluid m-0 p-0 ${!profile && (`vh-100 d-flex flex-column`)}`}>
  return (
    <div id="contentBar" className='container px-0'> 
      {formData && (
          <ContentForm view={view} setView={setView} showSearch={showSearch} setShowSearch={setShowSearch} onSubmit={handleSearch} setFormData={setFormData} formData={formData} feedType={feedType} setFeedType={setFeedType} profile={profile} rootLocation={ profile ? `u/${profile.Username}` : `search` } setActiveTab={setActiveTab} activeTab={activeTab} customisation={customisation} additionalOptions={additionalOptions} />
      )}
      {formData && (
          <ContentResults view={view} setView={setView} showSearch={showSearch} search={search} formData={formData} searchTriggered={searchTriggered} setSearchTriggered={setSearchTriggered} alternateUsers={alternateUsers}  currentUser={currentUser} preferences={preferences} accessGroups={accessGroups} feedType={feedType} profile={profile} rootLocation={ profile ? `u/${profile.Username}` : `search` } setActiveTab={setActiveTab} activeTab={activeTab} />
      )}
    </div>
  );

};
