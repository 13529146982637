import React, { createContext, useState, useEffect, useContext } from 'react';
import { deso_api, deso_graphql } from '../utils/graphql';
import { getProfileFromCache } from '../services/profileCache';
import { getUserAssociations, getPostAssociations, getHodlersForUser } from 'deso-protocol';
import { useUserPreferences } from './UserPreferences';
import { DeSoIdentityContext } from 'react-deso-protocol';

// Create the context
export const AppDataContext = createContext();

// Create the context provider component
export const AppDataProvider = ({ children }) => {
  const { currentUser } = useContext(DeSoIdentityContext);
  const { preferences } = useUserPreferences();
  const [appData, setAppData] = useState({}); // Data from first API (/api.php)
  const [stats, setStats] = useState(null); // Data from second API (deso_graphql)
  const [loading, setLoading] = useState(true);
  const [onlineUsers, setOnlineUsers] = useState([]);

  const [userModerationFlags, setUserModerationFlags] = useState({});
  const [postModerationFlags, setPostModerationFlags] = useState({});

  const [existingPost, setExistingPost] = useState(null);
  const [postsToRefresh, setPostsToRefresh] = useState([]);

  const addPostToRefresh = (postHashHex) => {
        setPostsToRefresh((prev) => [...prev, postHashHex]);
    };
  const setPostRefreshed = (PostHashHex) => {
      setPostsToRefresh((prevPosts) => prevPosts.filter(post => post !== PostHashHex));
  };

  const fetchExchangeData = async () => {
    try {
      const response = await fetch('/exchanges.json'); // Load from the public folder
      const exchangeData = await response.json();
      //console.log("exchangeData:", exchangeData);
      setAppData((prevAppData) => ({
        ...(prevAppData || {}),
        exchangeKeys: exchangeData, // Add exchange data to appData
      }));
    } catch (error) {
      console.error('exchangeData: Error fetching exchange data:', error);
    }
  };

  // Function to fetch data from the first API
  const fetchApiData = async () => {
    try {
      const response = await fetch('https://beyondsocial.app/api.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ endpoint: 'pageload' }),
      });
      const data = await response.json();
      if (data) {
        //setAppData(data); // Only update if the API returns valid data
        setAppData((prevAppData) => ({
          // Initialize prevAppData if null or undefined
          ...(prevAppData || {}),
          ...data,
        }));
      }
    } catch (error) {
      console.error('Error fetching API data:', error);
      // If the API call fails, leave the previous `appData` intact
    }
  };

  const fetchModerationData = async () => {
    try {
      const params = {
        TransactorPublicKeyBase58Check: process.env.REACT_APP_SYSTEM_KEY,
        TargetUserPublicKeyBase58Check: process.env.REACT_APP_SYSTEM_KEY,
        AssociationType: 'FLAG',
      };
      const response = await getUserAssociations(params);
      if (response) {
        const sortedAssociations = response.Associations.sort((a, b) => {
          const titleA = a.ExtraData?.title?.toLowerCase() || '';
          const titleB = b.ExtraData?.title?.toLowerCase() || '';
          return titleA.localeCompare(titleB);
        });
      
        setAppData((prevAppData) => ({
          // If prevAppData is null or undefined, initialize it as an empty object
          ...(prevAppData || {}),
            moderationFlags: [
              // Ensure moderationFlags exists and append new data
              ...(prevAppData?.data?.moderationFlags || []),
              ...sortedAssociations, // Add sorted response data
            ],
        }));
      }
      
      
      
    } catch (error) {
      console.error('Error fetching moderation data:', error);
      // If the API call fails, leave the previous `appData` intact
    }
  };

  const fetchTopicData = async () => {
    try {
      const params = {
        TransactorPublicKeyBase58Check: process.env.REACT_APP_SYSTEM_KEY,
        TargetUserPublicKeyBase58Check: process.env.REACT_APP_SYSTEM_KEY,
        AssociationType: 'TOPIC',
      };
      const response = await getUserAssociations(params);
      if (response) {
        const sortedAssociations = response.Associations.sort((a, b) => {
          const titleA = a.ExtraData?.title?.toLowerCase() || '';
          const titleB = b.ExtraData?.title?.toLowerCase() || '';
          return titleA.localeCompare(titleB);
        });
      
        setAppData((prevAppData) => ({
          // If prevAppData is null or undefined, initialize it as an empty object
          ...(prevAppData || {}),
            contentTopics: [
              // Ensure moderationFlags exists and append new data
              ...(prevAppData?.data?.moderationFlags || []),
              ...sortedAssociations, // Add sorted response data
            ],
        }));
      }
      
      
      
    } catch (error) {
      console.error('Error fetching moderation data:', error);
      // If the API call fails, leave the previous `appData` intact
    }
  };

  // Function to fetch data from the GraphQL API
  const fetchGraphQLData = async () => {
    try {
      const data = await deso_graphql({
        query: `query dashboardStats {
          dashboardStats {
            nodes {
              txnCountAll
              txnCount30D
              walletCountAll
              activeWalletCount30D
              newWalletCount30D
              blockHeightCurrent
              txnCountPending
              txnFee1D
              totalSupply
              postCount
              postLongformCount
              commentCount
              repostCount
              txnCountCreatorCoin
              txnCountNft
              txnCountDex
              txnCountSocial
              followCount
              messageCount
            }
          }
        }`,
        variables: null,
        operationName: "dashboardStats"
      });
      if (data?.data?.dashboardStats?.nodes[0]) {
        setStats(data.data.dashboardStats.nodes[0]);  // Only update if the GraphQL call returns valid data
      }
    } catch (error) {
      console.error('Error fetching GraphQL data:', error);
      // If the GraphQL call fails, leave the previous `stats` intact
    }
  };

  const fetchCoinHolders = async () => {
    // fetch the current user's coin holders
    try {
      const request = {
        "NumToFetch": 500,
        "PublicKeyBase58Check": currentUser.PublicKeyBase58Check,
      }
      const holders = await getHodlersForUser(request);
      setAppData((prevAppData) => ({
        // If prevAppData is null or undefined, initialize it as an empty object
        ...(prevAppData || {}),
          coinHolders: holders,
      }));
    } catch (error) {

    }
  }

  const fetchTokenHolders = async () => {
    // fetch the current user's coin holders
    try {
      const request = {
        "NumToFetch": 500,
        "PublicKeyBase58Check": "BC1YLfzejg1ak9KuSDchYpwT9VGjwmyVWH1GCHJRRoaPbkJezG5pCRA", //currentUser.PublicKeyBase58Check,
        "IsDAOCoin": true,
      }
      const holders = await getHodlersForUser(request);
      setAppData((prevAppData) => ({
        // If prevAppData is null or undefined, initialize it as an empty object
        ...(prevAppData || {}),
          tokenHolders: holders,
      }));
    } catch (error) {

    }
  }

  useEffect(() => {
    if (currentUser?.PublicKeyBase58Check) {
      fetchCoinHolders();
      fetchTokenHolders();
    }
  }, [currentUser]);

  useEffect(() => {
    const fetchOnlineUsers = async () => {
      if (appData?.onlineUsers) {  // Assuming appData has online users' public keys
        const userKeys = appData.onlineUsers;  // List of public keys of online users
        const onlineProfiles = await Promise.all(
          userKeys.map((publicKey) => getProfileFromCache(publicKey))
        );
        setOnlineUsers(onlineProfiles);  // Store the profiles of online users
      }
    };

    if (appData) {
      fetchOnlineUsers(); // Fetch profiles when appData is loaded
    }
  }, [appData]);

  // Main function to handle both API calls
  const fetchData = async () => {
    setLoading(true);

    // Fetch both APIs in parallel, allowing each to fail independently
    await Promise.all([fetchApiData(), fetchGraphQLData(), fetchExchangeData()]);

    setLoading(false);
  };

    // Initial data load and polling setup
    useEffect(() => {
      // Fetch the initial data when the app loads
      fetchData();
      fetchModerationData();
      fetchTopicData();
      // Set up polling every 90 seconds (90,000 milliseconds)
      const intervalId = setInterval(() => {
        fetchData();
      }, 40000);
  
      // Cleanup the interval on component unmount
      return () => clearInterval(intervalId);
    }, []);
  
  
    const MAX_ASSOCIATION_VALUES_PER_REQUEST = 10; // Assuming the limit is 10 per request

// Helper function to split array into chunks
const chunkArray = (array, chunkSize) => {
  const results = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    results.push(array.slice(i, i + chunkSize));
  }
  return results;
};

const fetchModerationFlags = async (postOrPublicKey) => {
  let moderationOutcome = true; // Default state is to allow the post
  
  // Determine if we're dealing with a post object or just a public key
  const isPost = postOrPublicKey && postOrPublicKey.PostHashHex && postOrPublicKey.PosterPublicKeyBase58Check;
  const userPublicKey = isPost ? postOrPublicKey.PosterPublicKeyBase58Check : postOrPublicKey;
  const postHashHex = isPost ? postOrPublicKey.PostHashHex : null;

  // Fetch user preferences
  const flagsToLookup = Object.keys(preferences.moderationSettings || {}).filter(
    (key) => preferences.moderationSettings[key] === null || preferences.moderationSettings[key] === false
  );

  // Proceed if there are flags to look up
  if (flagsToLookup.length > 0) {
    // Step 1: Check if we already hold the user flags
    let userFlags = userPublicKey && userModerationFlags[userPublicKey];

    if (!userFlags) {
      userFlags = [];
      // If we don't have the user flags, fetch them in chunks
      const flagBatches = chunkArray(flagsToLookup, MAX_ASSOCIATION_VALUES_PER_REQUEST);
      
      try {
        // Loop over flag batches and fetch user flags for each chunk
        for (const batch of flagBatches) {
          const userAssociationRequest = {
            TargetUserPublicKeyBase58Check: userPublicKey,
            AssociationType: 'FLAG',
            AssociationValues: batch, // Send only the current chunk of flags
          };

          // Fetch user flags from the API
          const userFlagsResponse = await getUserAssociations(userAssociationRequest);
          const batchFlags = userFlagsResponse?.Associations?.map(a => a.AssociationValue) || [];
          
          // Accumulate the results
          userFlags = [...userFlags, ...batchFlags];
        }

        // Cache the user flags in context
        setUserModerationFlags(prev => ({
          ...prev,
          [userPublicKey]: userFlags,
        }));

      } catch (error) {
        console.error('Error fetching user associations:', error);
      }
    }

    // Step 2: Now we have the user flags (either already held or just fetched)
    // Check user flags to decide moderation outcome
    userFlags.forEach(flag => {
      if (preferences.moderationSettings[flag] === false) {
        moderationOutcome = false; // Block the post
      } else if (preferences.moderationSettings[flag] === null && moderationOutcome !== false) {
        moderationOutcome = null; // Mask the post
      }
    });

    // Step 3: If the user is blocked, no need to check post flags
    if (moderationOutcome === false) {
      return moderationOutcome;
    }

    // Step 4: Check post flags if the user is not blocked
    if (postHashHex) {
      let postFlags = postModerationFlags[postHashHex];

      if (!postFlags) {
        postFlags = [];
        try {
          // Fetch post flags in chunks, similar to user flags
          const flagBatches = chunkArray(flagsToLookup, MAX_ASSOCIATION_VALUES_PER_REQUEST);

          for (const batch of flagBatches) {
            const postAssociationRequest = {
              PostHashHex: postHashHex,
              AssociationType: 'FLAG',
              AssociationValues: batch, // Send only the current chunk of flags
            };

            // Fetch post flags from the API
            const postFlagsResponse = await getPostAssociations(postAssociationRequest);
            const batchFlags = postFlagsResponse?.Associations?.map(a => a.AssociationValue) || [];
            
            // Accumulate the results
            postFlags = [...postFlags, ...batchFlags];
          }

          // Cache post flags in context
          setPostModerationFlags(prev => ({
            ...prev,
            [postHashHex]: postFlags,
          }));

        } catch (error) {
          console.error('Error fetching post associations:', error);
        }
      }

      // Check post flags to decide moderation outcome
      postFlags.forEach(flag => {
        if (preferences.moderationSettings[flag] === false) {
          moderationOutcome = false; // Block the post
        } else if (preferences.moderationSettings[flag] === null && moderationOutcome !== false) {
          moderationOutcome = null; // Mask the post
        }
      });
    }
  }

  return moderationOutcome;
};

    
    
    
    
    
    

  /*useEffect(() => {
    console.log("AppData:",appData,"Stats:",stats);
  }, [appData, stats]);*/
  

  // Provide the appData, stats, and loading state to the rest of the app
  return (
    <AppDataContext.Provider value={{ appData, stats, loading, userModerationFlags, postModerationFlags, fetchModerationFlags, existingPost, setExistingPost, postsToRefresh, addPostToRefresh, setPostRefreshed }}>
      {children}
    </AppDataContext.Provider>
  );
};
