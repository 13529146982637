import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Row, Card, Offcanvas, Nav, Tab, Form, Col, Button } from 'react-bootstrap';

import { coinGated } from '../contexts/UserPreferences';
import { MediaAttachments, MediaLibrary, PostButtons, PreviewPost, addMediaToPost, handleButtonClick, handleChange, handleDeleteEmbed, handleDeleteImage, handleDeleteVideo, handleEmbedInputChange, handleFileChange, handleInsertEmbed, handleInsertImage, handleInsertVideo, handlePaste, handleUpload, processVideo, quillUploadHandler, removeMediaFromPost, toggleEmbedInput, toggleMediaLibrary } from '../utils/compose';
import { Link } from 'react-router-dom';

const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), wait);
    };
};

const lightThemeStyles = {
    palette: {
      backgroundColor: '#ffffff',
      backgroundColorBody: '#f0f0f0',
      backgroundColorSecondary: '#e0e0e0',
      backgroundColorTertiary: '#d0d0d0',
      tabColorActive: '#0dcaf0',
      //headerBackgroundColor: '#ffffff',
      // Add other light theme specific styles
    },
  };
  
  const darkThemeStyles = {
    palette: {
      backgroundColor: '#000000',
      backgroundColorBody: '#212529',
      backgroundColorSecondary: '#343a40',
      backgroundColorTertiary: '#2b3035',
      tabColorActive: '#0dcaf0',
      //headerBackgroundColor: '#000000',
      // Add other dark theme specific styles
    },
  };

export const defaultStyles = {
    palette: {
      backgroundColor: null,
      backgroundColorBody: null,
      backgroundColorSecondary: null,
      backgroundColorTertiary: null,
      headerBackgroundColor: null,
      border: null,
      borderSecondary: null,
      borderTertiary: null,
      links: null,
      tabColor: null,
      tabColorActive: null,
      bodyColor: null,
      bodyFont: null,
      headingColor: null,
      headingFont: null,
      textAlignProfile: null,
      bannerAspect: null,
      imageAspect: null,
      videoAspect: null,
      backgroundImage: null,
  },
  defaultTab: 'feed',
  modules: {
    /*
      0: { 
        type: 'embed', 
        nature: 'audio',
        embedUrl: 'https://open.spotify.com/track/4kzvAGJirpZ9ethvKZdJtg?si=3149fdfd6036484f',
      },*/
      1: {
        type: 'text',
        content: 'Example Text Block, with example embed above and example image gallery below.',
      },
      2: {
        type: 'carousel', // add gallery for grid too
        nature: 'media',
        images: {
          'https://images.deso.org/4cb01ffcae9eaf6d0ec842d3312e2992e75ed254db19a29aeef8a788ea2ce3e5.webp': { PostHashHex: '5563cfaa85aba5d4edb84ae0c872fe8201a196b6c53ab3c654d771aed24d5b92' },
          'https://images.deso.org/e07acf50be0064861643b52a95170ce21184507b91eb3a785929aaf7c80fda1e.webp': { PostHashHex: '76bd20c672f7c9d7de8ed1c92a25f2031a9c2a02394d8b54d7fc03970d0bb798' },
          'https://images.deso.org/dc4c15e4a32d7edd06658a5ffff45a3cb7e47d02304c43e7497b05f7e2ee7782.webp': { PostHashHex: '215fba4d74c9de92f93e7d649c7fc6ec5e0b3e1588736677a6ae55e1336ed843' },
          'https://images.deso.org/169d41d2de1b2ee0281f39cc69189e0c3561592ba90eac0f3948876b9c511859.webp': { PostHashHex: 'b967061ac3ee02612585d3925427084192f0114aa3145312aa51bfa1a4772e94' },
          'https://images.deso.org/aa6a5252345db1d69e5b5815208e4606bb1bf9c4c4e056e8a0877dbe33357e4f.webp': { PostHashHex: '049614cb2100ad8941435c7dbde8294837735f4a315e2665dd923b3fbfd45390' },
        },
      },
      3: {
        type: 'profile',
      },
      /*
      4: {
        type: 'embed',
        embedUrl: 'https://onedrive.live.com/embed?resid=F679F13D5B5C50E5%21581207&authkey=%21ACUtN4sYzM1xpMM&width=3812&height=2860',
      },*/
  },
}

export const CustomisationUI = ({ currentUser, preferences, customisation, customiseProfile, setCustomiseProfile, setCustomisation }) => {
    const [selectedTab, setSelectedTab] = useState('palette'); // Default to colors tab
    const [userAccess, setUserAccess] = useState(false);
    const [showMediaLibrary, setShowMediaLibrary] = useState(false);
    const inputFileRef = useRef(null);
    const [height, setHeight] = useState('350px'); // Initial height
    const resizing = useRef(false); // Ref to track resizing state
    const startY = useRef(0); // Ref to store initial Y position
    const offcanvasRef = useRef(null); // Ref for the Offcanvas element

    useEffect(() => {
      // Determine the current theme from preferences
      const theme = preferences && preferences.theme; // Will be 'dark' or 'light'
  
      // Select the correct theme's default styles
      const themeDefaults = theme === 'light' ? lightThemeStyles : darkThemeStyles;
      //console.log("[CustomiseUX.jsx] Theme, Preferences at start:",theme, preferences);
      // Merge the defaultStyles with the selected theme's palette
      const mergedDefaults = {
          ...defaultStyles,
          palette: {
              ...defaultStyles.palette,
              ...themeDefaults.palette, // Merge theme-specific palette styles
          },
      };
  
      // Check if customisation or ExtraData is not set, and initialize with the merged defaults
      if (!customisation || !customisation.ExtraData) {
          setCustomisation(prevCustomisation => ({
              ...prevCustomisation,
              ExtraData: mergedDefaults
          }));
      }
    }, [preferences]);  

    const toggleMediaLibrary = () => {
        setShowMediaLibrary(!showMediaLibrary);
    };
    const setBackgroundImage = (selectedImage) => {
        setCustomisation(prevCustomisation => ({
            ...prevCustomisation,
            ExtraData: {
                ...prevCustomisation.ExtraData,
                palette: {
                    ...prevCustomisation.ExtraData.palette,
                    backgroundImage: selectedImage ? selectedImage : null
                }
            }
        }));
    };
    
    const handleTabChange = (tab) => {
      setSelectedTab(tab);
    };
  
    const handleToggleChange = (key, value) => {
        setCustomisation(prevCustomisation => ({
            ...prevCustomisation,
            ExtraData: {
                ...prevCustomisation.ExtraData,
                palette: {
                    ...prevCustomisation.ExtraData.palette,
                    [key]: value
                }
            }
        }));
    };
    
    const handleColorChangeDebounced = useCallback(
        debounce((colorProperty, color) => {
            setCustomisation((prevCustomisation) => ({
            ...prevCustomisation,
            ExtraData: {
                ...prevCustomisation.ExtraData,
                palette: {
                ...prevCustomisation.ExtraData.palette,
                [colorProperty]: color
                }
            }
            }));
        }, 300), // 300ms debounce delay
        []
    );

    const handleColorChange = (colorProperty, color) => {
        handleColorChangeDebounced(colorProperty, color);
    };
  
    const handleFontChange = (fontProperty, font) => {
      // Update the corresponding font property in the customisation state
      setCustomisation((prevCustomisation) => ({
        ...prevCustomisation,
        ExtraData: {
          ...prevCustomisation.ExtraData,
          palette: {
            ...prevCustomisation.ExtraData.palette,
            [fontProperty]: font
          }
        }
      }));
    };
  
    const handleAspectChange = (aspectProperty, aspect) => {
      // Update the corresponding aspect ratio property in the customisation state
      setCustomisation((prevCustomisation) => ({
        ...prevCustomisation,
        ExtraData: {
          ...prevCustomisation.ExtraData,
          palette: {
            ...prevCustomisation.ExtraData.palette,
            [aspectProperty]: aspect
          }
        }
      }));
    };

    // Function to reset a specific property to its default value
    const resetPropertyToDefault = (propertyName, preferences) => {
      // Determine the theme from preferences (either 'dark' or 'light')
      const theme = preferences && preferences.theme; // Will be 'dark' or 'light'

      // Select the correct theme's default styles based on the preferences
      const themeDefaults = theme === 'light' ? lightThemeStyles : darkThemeStyles;

      // Get the default value for the specified property
      const defaultValue = themeDefaults.palette[propertyName] ?? defaultStyles.palette[propertyName];

      // Update the customisation state with the default value
      setCustomisation(prevCustomisation => ({
          ...prevCustomisation,
          ExtraData: {
              ...prevCustomisation.ExtraData,
              palette: {
                  ...prevCustomisation.ExtraData.palette,
                  [propertyName]: defaultValue
              }
          }
      }));
    };
  
    useEffect(() => {
        async function checkAccess() {
          try {
            const access = await coinGated(currentUser, 1);
            setUserAccess(access);
          } catch (error) {
            console.error('Error checking access:', error);
          }
        }
    
        if (!customisation || !customisation.ExtraData) {
          const defaultStyles = !preferences || preferences.theme === 'dark' ? darkThemeStyles : lightThemeStyles;
    
          setCustomisation(prevCustomisation => ({
            ...prevCustomisation,
            ExtraData: defaultStyles
          }));
        }
    
        checkAccess();
      }, [customisation, setCustomisation, currentUser]);
      

    const handleStartResize = (event) => {
        resizing.current = true;
        startY.current = event.clientY || event.touches[0].clientY; // Initial Y position
        document.addEventListener('mousemove', handleResize);
        document.addEventListener('mouseup', stopResize);
        document.addEventListener('touchmove', handleResize);
        document.addEventListener('touchend', stopResize);
    };

    const handleResize = (event) => {
        if (!resizing.current) return;
        const currentY = event.clientY || event.touches[0].clientY;
        const deltaY = startY.current - currentY;
        startY.current = currentY;
        setHeight(prevHeight => `${Math.max(100, parseInt(prevHeight) + deltaY)}px`);
    };

    const stopResize = () => {
        resizing.current = false;
        document.removeEventListener('mousemove', handleResize);
        document.removeEventListener('mouseup', stopResize);
        document.removeEventListener('touchmove', handleResize);
        document.removeEventListener('touchend', stopResize);
    };

    return (
    <>
        {showMediaLibrary && (<MediaLibrary currentUser={currentUser} preferences={preferences} toggleMediaLibrary={toggleMediaLibrary} addMediaToPost={setBackgroundImage} removeMediaFromPost={null} VideoURLs={null} ImageURLs={null} />)}
        <Offcanvas
            className='bg-body bg-opacity-50 rounded-4 customiseProfile'
            show={customiseProfile}
            onHide={() => setCustomiseProfile(false)}
            placement="bottom"
            backdrop={false}
            scroll={true}
            style={{ height: height, zIndex: "12000" }}
        >
            <Offcanvas.Header 
              className='px-3 py-2 align-items-center justify-content-between header' 
              onMouseDown={handleStartResize} 
              onTouchStart={handleStartResize}
              closeButton
            >
                <Offcanvas.Title className='flex-shrink'>Custom Profile</Offcanvas.Title>
                <div className='flex-fill mx-4'><hr/></div>
                { userAccess ? (
                    <span className='flex-shrink btn btn-sm btn-success me-2'><i className="bi bi-floppy-fill px-1 me-2"></i>Save</span>
                ) : (
                    <Link to={`/features`} className='flex-shrink btn btn-sm btn-outline-info me-2'><i className="bi bi-shield-lock-fill px-1 me-2"></i>Unlock Feature</Link>
                )}
            </Offcanvas.Header>
            <Nav variant="underline" justify={true} className="px-3" style={{ minHeight: "2.75rem" }} activeKey={selectedTab} onSelect={(key) => handleTabChange(key)}>
                <Nav.Item>
                    <Nav.Link eventKey="palette">
                        <i className="bi bi-palette-fill me-2"></i>
                        Palette
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="effects">
                        <i className="bi bi-magic me-2"></i>
                        Effects
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="fonts">
                        <i className="bi bi-fonts me-2"></i>
                        Fonts
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="media">
                        <i className="bi bi-aspect-ratio-fill me-2"></i>
                        Media
                    </Nav.Link>
                </Nav.Item>
            </Nav>
            <Offcanvas.Body style={{ height: "auto", zIndex: "12000" }}>
                <Tab.Container activeKey={selectedTab}>
                    <Tab.Content>
                        <Tab.Pane eventKey="palette">
                          <div className='row'>
                            <div className="col-12 col-md-6 col-xl-4 mb-3">
                              <h5>Background</h5>
                              <div className='row align-items-center mb-1'>
                                <div className="col-12 d-flex align-items-center">
                                  <Form.Group className="flex-shrink me-2">
                                      <Form.Label htmlFor="backgroundColorBodyPicker" className="visually-hidden">Main</Form.Label>
                                      <Form.Control type="color" id="backgroundColorBodyPicker" value={customisation?.ExtraData?.palette?.backgroundColor} onChange={(e) => handleColorChange('backgroundColor', e.target.value)} />
                                  </Form.Group>
                                  <div className="flex-fill mt-1">
                                      Background Fill
                                  </div>
                                  <span onClick={() => resetPropertyToDefault("backgroundColor",preferences)} className="flex-shrink btn action h-100 d-flex flex-row flex-nowrap">
                                      <i className="bi bi-arrow-clockwise"></i><span className='d-none d-lg-block ms-2'>Reset</span>
                                  </span>
                                </div>
                              </div>
                              <div className='row align-items-center mb-1'>
                                <div className="col-12 d-flex align-items-center flex-row flex-nowrap">
                                    <Form.Group className="me-2">
                                    {customisation?.ExtraData?.palette?.backgroundImage ? (
                                        <img
                                            src={customisation?.ExtraData?.palette?.backgroundImage}
                                            className='form-control form-control-color'
                                            alt="Selected Background"
                                            style={{ height: "38px", maxWidth: "48px", width: "48px", objectFit: "fill" }}
                                        />
                                    ) : (
                                      <div
                                            className='form-control form-control-color text-center'
                                            alt="No Background Image"
                                            style={{ height: "38px" }}
                                      >
                                        <i className="bi bi-x-lg text-body bg-black rounded px-2"></i>
                                      </div>
                                    )}
                                    </Form.Group>
                                  <div className="flex-fill">
                                      Background Image
                                  </div> 
                                  <span onClick={toggleMediaLibrary} className="btn action h-100 d-flex flex-row flex-nowrap">
                                      <i className="bi bi-images"></i> <span className='d-none d-lg-block ms-2'>Library</span>
                                  </span>
                                  <span onClick={() => handleButtonClick("composeFileUpload")} className="btn action h-100 d-flex flex-row flex-nowrap">
                                      <i className="bi bi-upload"></i> <span className='d-none d-lg-block ms-2'>Upload</span>
                                  </span>
                                  <input
                                      type="file"
                                      accept="image/*, video/*" 
                                      id="composeFileUpload"
                                      ref={inputFileRef}
                                      style={{ display: 'none' }}
                                      onChange={handleFileChange}
                                  />
                                  <span onClick={() => resetPropertyToDefault("backgroundImage",preferences)} className="flex-shrink btn action h-100 d-flex flex-row flex-nowrap">
                                      <i className="bi bi-arrow-clockwise"></i><span className='d-none d-lg-block ms-2'>Reset</span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-4 mb-3">
                              <div className='row align-items-center mb-1'>
                                <h5>Profile</h5>
                                <div className="col-12 d-flex align-items-center">
                                  <Form.Group className="flex-shrink me-2">
                                      <Form.Label htmlFor="headerBackgroundColor" className="visually-hidden">Main</Form.Label>
                                      <Form.Control type="color" id="backgroundColorPicker" value={customisation?.ExtraData?.palette?.headerBackgroundColor} onChange={(e) => handleColorChange('headerBackgroundColor', e.target.value)} />
                                  </Form.Group>
                                  <div className="flex-fill mt-1">
                                    Header Background
                                  </div>
                                  <span onClick={() => resetPropertyToDefault("headerBackgroundColor",preferences)} className="flex-shrink btn action h-100 d-flex flex-row flex-nowrap">
                                      <i className="bi bi-arrow-clockwise"></i><span className='d-none d-lg-block ms-2'>Reset</span>
                                  </span>
                                </div>
                                <div className="col-12 d-flex align-items-center">
                                  <Form.Group className="flex-shrink me-2">
                                      <Form.Label htmlFor="tabColorActivePicker" className="visually-hidden">Active Tab</Form.Label>
                                      <Form.Control type="color" id="tabColorActivePicker" value={customisation?.ExtraData?.palette?.tabColorActive} onChange={(e) => handleColorChange('tabColorActive', e.target.value)} />
                                  </Form.Group>
                                  <div className='flex-fill mt-1'>Active Feed Tab</div>
                                  <span onClick={() => resetPropertyToDefault("tabColorActive",preferences)} className="flex-shrink btn action h-100 d-flex flex-row flex-nowrap">
                                      <i className="bi bi-arrow-clockwise"></i><span className='d-none d-lg-block ms-2'>Reset</span>
                                  </span>
                                </div>
                              </div>
                              <div className='row align-items-center mb-3'>
                                <div className="col-12 d-flex align-items-center">
                                  <Form.Group className="flex-shrink me-2">
                                      <Form.Label htmlFor="tabColorPicker" className="visually-hidden">Tabs</Form.Label>
                                      <Form.Control type="color" id="tabColorPicker" value={customisation?.ExtraData?.palette?.tabColor} onChange={(e) => handleColorChange('tabColor', e.target.value)} />
                                  </Form.Group>
                                  <div className='flex-fill mt-1'>Other Feed Tabs</div>
                                  <span onClick={() => resetPropertyToDefault("tabColor",preferences)} className="flex-shrink btn action h-100 d-flex flex-row flex-nowrap">
                                      <i className="bi bi-arrow-clockwise"></i><span className='d-none d-lg-block ms-2'>Reset</span>
                                  </span>
                                </div>  
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-4 mb-3">
                              <div className='row align-items-center mb-3'>
                                <div className="col-12 d-flex align-items-center">
                                  <h5 className='col-4'>Posts</h5>
                                  <h6 className='col-4'>background</h6>
                                  <h6 className='col-4'>border</h6>
                                </div>
                              </div>
                              <div className='row align-items-center mb-1'>
                                <div className="col-12 d-flex align-items-center">
                                  <h6 className='col-4'>Posts</h6>
                                  <div className='col-4 d-flex align-items-center'>
                                    <Form.Group className="me-2 flex-shrink">
                                        <Form.Label htmlFor="backgroundColorBodyPicker" className="visually-hidden">Primary</Form.Label>
                                        <Form.Control type="color" id="backgroundColorBodyPicker" value={customisation?.ExtraData?.palette?.backgroundColorBody} onChange={(e) => handleColorChange('backgroundColorBody', e.target.value)} />
                                    </Form.Group>
                                    <span onClick={() => resetPropertyToDefault("backgroundColorBody",preferences)} className="flex-shrink btn action h-100 d-flex flex-row flex-nowrap">
                                        <i className="bi bi-arrow-clockwise"></i><span className='d-none d-lg-block ms-2'>Reset</span>
                                    </span>
                                  </div>
                                  <div className='col-4 d-flex align-items-center'>
                                    <Form.Group className="me-2 flex-shrink">
                                      <Form.Label htmlFor="borderPicker" className="visually-hidden">Main</Form.Label>
                                      <Form.Control type="color" id="borderPicker" value={customisation?.ExtraData?.palette?.border} onChange={(e) => handleColorChange('border', e.target.value)} />
                                    </Form.Group>
                                    <span onClick={() => resetPropertyToDefault("border",preferences)} className="flex-shrink btn action h-100 d-flex flex-row flex-nowrap">
                                        <i className="bi bi-arrow-clockwise"></i><span className='d-none d-lg-block ms-2'>Reset</span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className='row align-items-center mb-1'>
                                <div className="col-12 d-flex align-items-center">
                                  <h6 className='col-4'>Quotes</h6>
                                  <div className='col-4 d-flex align-items-center'>
                                    <Form.Group className="flex-shrink me-2">
                                        <Form.Label htmlFor="backgroundColorSecondaryPicker" className="visually-hidden">Secondary</Form.Label>
                                        <Form.Control type="color" id="backgroundColorSecondaryPicker" value={customisation?.ExtraData?.palette?.backgroundColorSecondary} onChange={(e) => handleColorChange('backgroundColorSecondary', e.target.value)} />
                                    </Form.Group>
                                    <span onClick={() => resetPropertyToDefault("backgroundColorSecondary",preferences)} className="flex-shrink btn action h-100 d-flex flex-row flex-nowrap">
                                        <i className="bi bi-arrow-clockwise"></i><span className='d-none d-lg-block ms-2'>Reset</span>
                                    </span>
                                  </div>
                                  <div className='col-4 d-flex align-items-center'>
                                    <Form.Group className="flex-shrink me-2">
                                      <Form.Label htmlFor="borderSecondaryColorPicker" className="visually-hidden">Secondary</Form.Label>
                                      <Form.Control type="color" id="borderSecondaryColorPicker" value={customisation?.ExtraData?.palette?.borderSecondary} onChange={(e) => handleColorChange('borderSecondary', e.target.value)} />
                                    </Form.Group>
                                    <span onClick={() => resetPropertyToDefault("borderSecondary",preferences)} className="flex-shrink btn action h-100 d-flex flex-row flex-nowrap">
                                        <i className="bi bi-arrow-clockwise"></i><span className='d-none d-lg-block ms-2'>Reset</span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className='row align-items-center mb-1'>
                                <div className="col-12 d-flex align-items-center">
                                  <h6 className='col-4'>Replies</h6>
                                  <div className='col-4 d-flex align-items-center'>
                                    <Form.Group className="flex-shrink me-2">
                                        <Form.Label htmlFor="backgroundColorTertiaryPicker" className="visually-hidden">Tertiary</Form.Label>
                                        <Form.Control type="color" id="backgroundColorTertiaryPicker" value={customisation?.ExtraData?.palette?.backgroundColorTertiary} onChange={(e) => handleColorChange('backgroundColorTertiary', e.target.value)} />
                                    </Form.Group>
                                    <span onClick={() => resetPropertyToDefault("backgroundColorTertiary",preferences)} className="flex-shrink btn action h-100 d-flex flex-row flex-nowrap">
                                        <i className="bi bi-arrow-clockwise"></i><span className='d-none d-lg-block ms-2'>Reset</span>
                                    </span>
                                  </div>
                                  <div className='col-4 d-flex align-items-center'>
                                    <Form.Group className="flex-shrink me-2">
                                      <Form.Label htmlFor="borderTertiaryColorPicker" className="visually-hidden">Tertiary</Form.Label>
                                      <Form.Control type="color" id="borderTertiaryColorPicker" value={customisation?.ExtraData?.palette?.borderTertiary} onChange={(e) => handleColorChange('borderTertiary', e.target.value)} />
                                    </Form.Group>
                                    <span onClick={() => resetPropertyToDefault("borderTertiary",preferences)} className="flex-shrink btn action h-100 d-flex flex-row flex-nowrap">
                                        <i className="bi bi-arrow-clockwise"></i><span className='d-none d-lg-block ms-2'>Reset</span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="effects">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-6 col-xl-4 mb-3">
                                <div className='row align-items-center mb-1'>
                                  <h6><i className="bi bi-magic me-2"></i>Effects</h6>
                                  <div className="col-12 d-flex align-items-center">
                                    <Form.Group className="flex-shrink me-2">
                                        <Form.Label htmlFor="glassEffectToggle" className="visually-hidden">Banner Reflection</Form.Label>
                                        <Form.Check 
                                            type="switch" 
                                            id="headerImageReflectToggle" 
                                            checked={customisation?.ExtraData?.palette?.headerImageReflect || false} 
                                            onChange={(e) => handleToggleChange('headerImageReflect', e.target.checked)} 
                                        />
                                    </Form.Group>
                                    <h6>Image Reflect</h6>
                                  </div>  
                                  <div className="col-12 d-flex align-items-center">
                                    <Form.Group className="flex-shrink me-2">
                                        <Form.Label htmlFor="headerBackgroundGradientToggle" className="visually-hidden">Background Gradient</Form.Label>
                                        <Form.Check 
                                            type="switch" 
                                            id="headerBackgroundGradientToggle" 
                                            checked={customisation?.ExtraData?.palette?.headerBackgroundGradient || false} 
                                            onChange={(e) => handleToggleChange('headerBackgroundGradient', e.target.checked)} 
                                        />
                                    </Form.Group>
                                    <h6>Gradient Fade</h6>
                                  </div>

                                  {customisation?.ExtraData?.palette?.backgroundImage && (
                                  <>
                                    <div className="col-12 d-flex align-items-center">
                                      <Form.Group className="flex-shrink me-2">
                                          <Form.Check 
                                              type="switch" 
                                              id="backgroundRepeat" 
                                              label=""
                                              checked={!!customisation?.ExtraData?.palette?.backgroundRepeat}
                                              onChange={(e) => handleToggleChange('backgroundRepeat', e.target.checked)}
                                          />
                                      </Form.Group>
                                      <h6>Background Repeat</h6>
                                    </div>
                                    <div className="col-12 d-flex align-items-center">
                                      <Form.Group className="flex-shrink me-2">
                                          <Form.Check 
                                              type="switch" 
                                              id="backgroundAttachment" 
                                              label=""
                                              checked={!!customisation?.ExtraData?.palette?.backgroundAttachment}
                                              onChange={(e) => handleToggleChange('backgroundAttachment', e.target.checked)}
                                          />
                                      </Form.Group>
                                      <h6>Static Background</h6>
                                    </div>
                                    <div className="col-12 d-flex align-items-center">
                                      <Form.Group className="flex-shrink me-2">
                                          <Form.Check 
                                              type="switch" 
                                              id="backgroundSize" 
                                              label=""
                                              checked={!!customisation?.ExtraData?.palette?.backgroundSize}
                                              onChange={(e) => handleToggleChange('backgroundSize', e.target.checked)}
                                          />
                                      </Form.Group>
                                      <h6>Background Size Cover</h6>
                                    </div>
                                  </>
                                  )}

                                <div className="col-12 d-flex align-items-center">
                                  <Form.Group className="flex-shrink me-2">
                                      <Form.Label htmlFor="glassEffectToggle" className="visually-hidden">Glass Effect</Form.Label>
                                      <Form.Check 
                                          type="switch" 
                                          id="glassEffectToggle" 
                                          checked={customisation?.ExtraData?.palette?.effectGlass || false} 
                                          onChange={(e) => handleToggleChange('effectGlass', e.target.checked)} 
                                      />
                                  </Form.Group>
                                  <h6>Glass transparency</h6>  
                                </div>
                                <div className="col-12 d-flex align-items-center">
                                  <Form.Group className="flex-shrink me-2">
                                      <Form.Label htmlFor="roundedEffectToggle" className="visually-hidden">Rounded Effect</Form.Label>
                                      <Form.Check 
                                          type="switch" 
                                          id="roundedEffectToggle" 
                                          checked={customisation?.ExtraData?.palette?.effectRounded || false} 
                                          onChange={(e) => handleToggleChange('effectRounded', e.target.checked)} 
                                      />
                                  </Form.Group>
                                  <h6>Rounded corners</h6>
                                </div>
                                <div className="col-12 d-flex align-items-center">
                                  <Form.Group className="flex-shrink me-2">
                                        <Form.Label htmlFor="shadowEffectToggle" className="visually-hidden">Shadow Effect</Form.Label>
                                        <Form.Check 
                                            type="switch" 
                                            id="shadowEffectToggle" 
                                            checked={customisation?.ExtraData?.palette?.effectShadow || false} 
                                            onChange={(e) => handleToggleChange('effectShadow', e.target.checked)} 
                                        />
                                    </Form.Group>
                                  <h6>Shadow</h6>
                                </div>
                                </div>
                              </div>
                            </div>  
                            
                        </Tab.Pane>
                        <Tab.Pane eventKey="fonts">
                        <div className="row align-items-center">
                            <div className='col-12'>
                            </div>
                            <div className='col-4 mb-3'>
                                <h6>Body Text</h6>
                            </div>
                            <div className='col-2 mb-3'>
                            <Form.Group className="row">
                                <Form.Label htmlFor="bodyColorPicker" className="visually-hidden">Body Color</Form.Label>
                                <Form.Control type="color" id="bodyColorPicker" value={customisation?.ExtraData?.palette?.bodyColor} onChange={(e) => handleColorChange('bodyColor', e.target.value)} />
                            </Form.Group>
                            </div>
                            <div className='col-6'>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="bodyFontSelect" className="visually-hidden">Body Font</Form.Label>
                                <Form.Control 
                                    as="select" 
                                    id="bodyFontSelect" 
                                    value={customisation?.ExtraData?.palette?.bodyFont || ''}
                                    onChange={(e) => handleFontChange('bodyFont', e.target.value)}
                                >
                                    <option value="">Default</option>
                                    <option value="Arial">Arial</option>
                                    <option value="Brush Script MT">Brush Script</option>
                                    <option value="Courier New">Courier New</option>
                                    <option value="'Apple Chancery',cursive">Cursive</option>
                                    <option value="Garamond">Garamond</option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Helvetica">Helvetica</option>
                                    <option value="Monospace">Monospace</option>
                                    <option value="Tahoma">Tahoma</option>
                                    <option value="Times New Roman">Times New Roman</option>
                                    <option value="Trebuchet MS">Trebuchet</option>
                                    <option value="Verdana">Verdana</option>
                                </Form.Control>
                            </Form.Group>

                            </div>
                            <div className='col-4 mb-3'>
                                <h6>Headings</h6>
                            </div>
                            <div className='col-2 mb-3'>
                            <Form.Group className="row">
                                <Form.Label htmlFor="headingColorPicker" className="visually-hidden">Heading Color</Form.Label>
                                <Form.Control type="color" id="headingColorPicker" value={customisation?.ExtraData?.palette?.headingColor} onChange={(e) => handleColorChange('headingColor', e.target.value)} />
                            </Form.Group>
                            </div>
                            <div className='col-6 mb-3'>
                                <Form.Group className="">
                                    <Form.Label htmlFor="headingFontSelect" className="visually-hidden">Heading Font</Form.Label>
                                    <Form.Control 
                                        as="select" 
                                        id="headingFontSelect" 
                                        value={customisation?.ExtraData?.palette?.headingFont || ''}
                                        onChange={(e) => handleFontChange('headingFont', e.target.value)}
                                    >
                                        <option value="">Default</option>
                                        <option value="Arial">Arial</option>
                                        <option value="Brush Script MT">Brush Script</option>
                                        <option value="Courier New">Courier New</option>
                                        <option value="Cursive">Cursive</option>
                                        <option value="Garamond">Garamond</option>
                                        <option value="Georgia">Georgia</option>
                                        <option value="Helvetica">Helvetica</option>
                                        <option value="Monospace">Monospace</option>
                                        <option value="Tahoma">Tahoma</option>
                                        <option value="Times New Roman">Times New Roman</option>
                                        <option value="Trebuchet MS">Trebuchet</option>
                                        <option value="Verdana">Verdana</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>
                            <div className="col-4 mb-3">
                                <h6>Links</h6>
                            </div>
                            <div className="col-2 mb-3">
                                <Form.Group className="row">
                                    <Form.Label htmlFor="linksColorPicker" className="visually-hidden">Links Color</Form.Label>
                                    <Form.Control type="color" id="linksColorPicker" value={customisation?.ExtraData?.palette?.links} onChange={(e) => handleColorChange('links', e.target.value)} />
                                </Form.Group>
                            </div>
                        </div>
                        {/* Add similar Form.Group components for other font settings */}
                        </Tab.Pane>
                        <Tab.Pane eventKey="media">
                            <Row className='align-items-center mb-3'>
                                <Col xs={6}>
                                    <Form.Label htmlFor="bannerAspectSelect">Banner Image Ratio</Form.Label>
                                </Col>
                                <Col xs={6}>
                                    <Form.Control className='text-center' as="select" id="bannerAspectSelect" value={customisation?.ExtraData?.palette?.bannerAspect} onChange={(e) => handleAspectChange('bannerAspect', e.target.value)}>
                                        <option value="4">4x1</option>
                                        <option value="3">3x1</option>
                                        <option value="2.333333333333333">21x9</option>
                                        <option value="2">2x1</option>
                                        <option value="1.777777777777778">16x9</option>
                                        <option value="1.5">3x2</option>
                                        <option value="1.333333333333333">4x3</option>
                                        <option value="1.25">5x4</option>
                                        <option value="1">1x1</option>
                                        <option value="0.8">4x5</option>
                                        <option value="0.75">3x4</option>
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className='align-items-center mb-3'>
                                <Col xs={6}>
                                    <Form.Label htmlFor="imageAspectSelect">Post Image Ratio</Form.Label>
                                </Col>
                                <Col xs={6}>
                                    <Form.Control className='text-center' as="select" id="imageAspectSelect" value={customisation?.ExtraData?.palette?.imageAspect} onChange={(e) => handleAspectChange('imageAspect', e.target.value)}>
                                        <option value="4">4x1</option>
                                        <option value="3">3x1</option>
                                        <option value="2.333333333333333">21x9</option>
                                        <option value="2">2x1</option>
                                        <option value="1.777777777777778">16x9</option>
                                        <option value="1.5">3x2</option>
                                        <option value="1.333333333333333">4x3</option>
                                        <option value="1.25">5x4</option>
                                        <option value="1">1x1</option>
                                        <option value="0.8">4x5</option>
                                        <option value="0.75">3x4</option>
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className='align-items-center mb-3'>
                                <Col xs={6}>
                                    <Form.Label htmlFor="videoAspectSelect">Video Ratio</Form.Label>
                                </Col>
                                <Col xs={6}>
                                    <Form.Control className='text-center' as="select" id="videoAspectSelect" value={customisation?.ExtraData?.palette?.videoAspect} onChange={(e) => handleAspectChange('videoAspect', e.target.value)}>
                                        <option value="4">4x1</option>
                                        <option value="3">3x1</option>
                                        <option value="2.333333333333333">21x9</option>
                                        <option value="2">2x1</option>
                                        <option value="1.777777777777778">16x9</option>
                                        <option value="1.5">3x2</option>
                                        <option value="1.333333333333333">4x3</option>
                                        <option value="1.25">5x4</option>
                                        <option value="1">1x1</option>
                                        <option value="0.8">4x5</option>
                                        <option value="0.75">3x4</option>
                                    </Form.Control>
                                </Col>
                            </Row>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </Offcanvas.Body>
        </Offcanvas>
    </>
    );
  };

  export const customisedExamples = {
    "ExampleUser": {
      TransactorPublicKeyBase58Check: null,
      TargetUserPublicKeyBase58Check: null,
      AppPublicKeyBase58Check: null,
      AssociationType: null, // Indicate default association
      AssociationValue: null, // Indicate default value
      ExtraData: {
        palette: {
          backgroundColor: '#0000df',
          backgroundColorSecondary: null,
          backgroundColorTertiary: null,
          headerBackgroundColor: '#2f191b',
          border: '#000000',
          borderSecondary: null,
          borderTertiary: null,
          links: null,
          tabColor: null,
          tabColorActive: null,
          bodyColor: null,
          bodyFont: null,
          headingColor: null,
          headingFont: null,
          textAlignProfile: null,
          bannerAspect: null,
          imageAspect: null,
          videoAspect: null,
          backgroundImage: null, // uploaded via the usual API
        },
        defaultTab: 'feed',
        modules: {
          0: {
            type: 'text',
            content: 'Example Text Block, with example embed above and example image gallery below.',
          },
          1: {
            type: 'embed',
            nature: 'audio',
            embedUrl: 'https://open.spotify.com/track/4kzvAGJirpZ9ethvKZdJtg?si=3149fdfd6036484f',
          },
          2: {
            type: 'pinned',
            count: 3,
          },
          3: {
            type: 'carousel', // add gallery for grid too
            nature: 'media',
            images: {
              'https://images.deso.org/4cb01ffcae9eaf6d0ec842d3312e2992e75ed254db19a29aeef8a788ea2ce3e5.webp': { PostHashHex: '5563cfaa85aba5d4edb84ae0c872fe8201a196b6c53ab3c654d771aed24d5b92' },
              'https://images.deso.org/e07acf50be0064861643b52a95170ce21184507b91eb3a785929aaf7c80fda1e.webp': { PostHashHex: '76bd20c672f7c9d7de8ed1c92a25f2031a9c2a02394d8b54d7fc03970d0bb798' },
              'https://images.deso.org/dc4c15e4a32d7edd06658a5ffff45a3cb7e47d02304c43e7497b05f7e2ee7782.webp': { PostHashHex: '215fba4d74c9de92f93e7d649c7fc6ec5e0b3e1588736677a6ae55e1336ed843' },
              'https://images.deso.org/169d41d2de1b2ee0281f39cc69189e0c3561592ba90eac0f3948876b9c511859.webp': { PostHashHex: 'b967061ac3ee02612585d3925427084192f0114aa3145312aa51bfa1a4772e94' },
              'https://images.deso.org/aa6a5252345db1d69e5b5815208e4606bb1bf9c4c4e056e8a0877dbe33357e4f.webp': { PostHashHex: '049614cb2100ad8941435c7dbde8294837735f4a315e2665dd923b3fbfd45390' },
            },
          },
          4: {
            type: 'profile',
          },
          /*
          4: {
            type: 'embed',
            embedUrl: 'https://onedrive.live.com/embed?resid=F679F13D5B5C50E5%21581207&authkey=%21ACUtN4sYzM1xpMM&width=3812&height=2860',
          },*/
        }
      }
    },
    "vampirecampfire": {
      TransactorPublicKeyBase58Check: null,
      TargetUserPublicKeyBase58Check: null,
      AppPublicKeyBase58Check: null,
      AssociationType: null, // Indicate default association
      AssociationValue: null, // Indicate default value
      ExtraData: {
        /*
        palette: {
          backgroundColor: '#0000df',
          backgroundColorSecondary: null,
          backgroundColorTertiary: null,
          headerBackgroundColor: '#2f191b',
          border: '#000000',
          borderSecondary: null,
          borderTertiary: null,
          links: null,
          tabColor: null,
          tabColorActive: null,
          bodyColor: null,
          bodyFont: null,
          headingColor: null,
          headingFont: null,
          textAlignProfile: null,
          bannerAspect: null,
          imageAspect: null,
          videoAspect: null,
          backgroundImage: null, // uploaded via the usual API
        },*/
        tabs: {
          'blog': { active: true },
          'eagle': { active: true, title: '🦅 Eagle', view: 'list', filter: { tabSearch: '#vceaglecrew' }, },
          'all': { active: true },
          'posts': { active: true },
          'discussions': { active: true },
          'images': { active: true },
          'videos': { active: true },
        },
        defaultTab: 'all',
      }
    },
    "BKPOWER8": {
      TransactorPublicKeyBase58Check: null,
      TargetUserPublicKeyBase58Check: null,
      AppPublicKeyBase58Check: null,
      AssociationType: null, // Indicate default association
      AssociationValue: null, // Indicate default value
      ExtraData: {
        /*
        palette: {
          backgroundColor: '#0000df',
          backgroundColorSecondary: null,
          backgroundColorTertiary: null,
          headerBackgroundColor: '#2f191b',
          border: '#000000',
          borderSecondary: null,
          borderTertiary: null,
          links: null,
          tabColor: null,
          tabColorActive: null,
          bodyColor: null,
          bodyFont: null,
          headingColor: null,
          headingFont: null,
          textAlignProfile: null,
          bannerAspect: null,
          imageAspect: null,
          videoAspect: null,
          backgroundImage: null, // uploaded via the usual API
        },*/
        tabs: {
          'blog': { active: true },
          'space': { active: true, title: '👩‍🚀 Space', view: 'list', filter: { tabSearch: '#givemespace' }, },
          'all': { active: true },
          'posts': { active: true },
          'discussions': { active: true },
          'images': { active: true },
          'videos': { active: true },
        },
        defaultTab: 'all',
      }
    },
    "StarGeezer": {
      TransactorPublicKeyBase58Check: null,
      TargetUserPublicKeyBase58Check: null,
      AppPublicKeyBase58Check: null,
      AssociationType: null, // Indicate default association
      AssociationValue: null, // Indicate default value
      ExtraData: {
        /*
        palette: {
          backgroundColor: '#0000df',
          backgroundColorSecondary: null,
          backgroundColorTertiary: null,
          headerBackgroundColor: '#2f191b',
          border: '#000000',
          borderSecondary: null,
          borderTertiary: null,
          links: null,
          tabColor: null,
          tabColorActive: null,
          bodyColor: null,
          bodyFont: null,
          headingColor: null,
          headingFont: null,
          textAlignProfile: null,
          bannerAspect: null,
          imageAspect: null,
          videoAspect: null,
          backgroundImage: null, // uploaded via the usual API
        },*/
        tabs: {
          'all': { active: true },
          'blog': { active: false },
          'space': { active: true, title: '🌌 Space', view: 'list', filter: { tabSearch: '#space' }, },
          'astronomy': { active: true, title: '🔭 Astronomy', view: 'list', filter: { tabSearch: '#astronomy' }, },
          'photography': { active: true, title: '📷 Photography', view: 'grid', filter: { tabSearch: '#photography', imageUrls: true }, },
          'dev': { active: true, title: '👩‍💻 Dev', view: 'list', filter: { tabSearch: '#devupdates' }, },
        },
        defaultTab: 'all',
        modules: {
          0: {
            type: 'text',
            content: 'Example Text Block, with example embed above and example image gallery below.',
          },
          1: {
            type: 'embed',
            nature: 'audio',
            embedUrl: 'https://open.spotify.com/track/4kzvAGJirpZ9ethvKZdJtg?si=3149fdfd6036484f',
          },
          2: {
            type: 'pinned',
            count: 3,
          },
          3: {
            type: 'carousel', // add gallery for grid too
            nature: 'media',
            images: {
              'https://images.deso.org/4cb01ffcae9eaf6d0ec842d3312e2992e75ed254db19a29aeef8a788ea2ce3e5.webp': { PostHashHex: '5563cfaa85aba5d4edb84ae0c872fe8201a196b6c53ab3c654d771aed24d5b92' },
              'https://images.deso.org/e07acf50be0064861643b52a95170ce21184507b91eb3a785929aaf7c80fda1e.webp': { PostHashHex: '76bd20c672f7c9d7de8ed1c92a25f2031a9c2a02394d8b54d7fc03970d0bb798' },
              'https://images.deso.org/dc4c15e4a32d7edd06658a5ffff45a3cb7e47d02304c43e7497b05f7e2ee7782.webp': { PostHashHex: '215fba4d74c9de92f93e7d649c7fc6ec5e0b3e1588736677a6ae55e1336ed843' },
              'https://images.deso.org/169d41d2de1b2ee0281f39cc69189e0c3561592ba90eac0f3948876b9c511859.webp': { PostHashHex: 'b967061ac3ee02612585d3925427084192f0114aa3145312aa51bfa1a4772e94' },
              'https://images.deso.org/aa6a5252345db1d69e5b5815208e4606bb1bf9c4c4e056e8a0877dbe33357e4f.webp': { PostHashHex: '049614cb2100ad8941435c7dbde8294837735f4a315e2665dd923b3fbfd45390' },
            },
          },
          4: {
            type: 'profile',
          },
          /*
          5: {
            type: 'embed',
            embedUrl: 'https://onedrive.live.com/embed?resid=F679F13D5B5C50E5%21581207&authkey=%21ACUtN4sYzM1xpMM&width=3812&height=2860',
          },*/
        }
      }
    },
    "ThisDayInMusicHistory": {
      TransactorPublicKeyBase58Check: null,
      TargetUserPublicKeyBase58Check: null,
      AppPublicKeyBase58Check: null,
      AssociationType: null, // Indicate default association
      AssociationValue: null, // Indicate default value
      ExtraData: {
        palette: {
          backgroundColor: '#000000',
          backgroundColorBody: '#000000',
          backgroundColorSecondary: '#301c1a',
          backgroundColorTertiary: null,
  
          /* header */
          headerColor: '#ffffff',
          headerBackgroundColor: '#301c1a',
          headerImageReflect: true,
          headerBackgroundGradient: true,
  
          /* effects */
          effectGlass: true,
          effectRounded: true,
          effectShadow: true,
  
          /* page accents */
          border: '#000000',
          borderSecondary: null,
          borderTertiary: null,
  
          links: null,
  
          tabColor: null,
          tabColorActive: '#000000',
  
          bodyColor: '#ffffff',
          bodyFont: null,
  
          headingColor: null,
          headingFont: null,
  
          textAlignProfile: 'center',
          bannerAspect: null,
          imageAspect: null,
          videoAspect: null,
          backgroundImage: null, // uploaded via the usual API
        },
        defaultTab: 'posts',
        tabs: {
          'otd': { active: true, title: 'OnThisDay', view: 'list', filter: { tabSearch: '#thisdayinmusic' }, },
          'music': { active: true, title: "Music", isFeed: true, feedType: 'music', filter: { embedVideoUrl: true, embedUrlFilter: ["mousai","spotify"] } },
          'posts': { active: true, view: 'list',},
          'discussion': { active: true, view: 'list' }
        },
        modules: {
          0: {
            type: 'text',
            content: 'Custom Text Block, with example embed below.',
          },
          1: {
            type: 'embed',
            nature: 'audio',
            embedUrl: 'https://open.spotify.com/track/4kzvAGJirpZ9ethvKZdJtg?si=3149fdfd6036484f',
          },
          2: {
            type: 'pinned',
            count: 3,
          },
          /*
          3: {
            type: 'carousel', // add gallery for grid too
            nature: 'media',
            images: {
              'https://images.deso.org/4cb01ffcae9eaf6d0ec842d3312e2992e75ed254db19a29aeef8a788ea2ce3e5.webp': { PostHashHex: '5563cfaa85aba5d4edb84ae0c872fe8201a196b6c53ab3c654d771aed24d5b92' },
              'https://images.deso.org/e07acf50be0064861643b52a95170ce21184507b91eb3a785929aaf7c80fda1e.webp': { PostHashHex: '76bd20c672f7c9d7de8ed1c92a25f2031a9c2a02394d8b54d7fc03970d0bb798' },
              'https://images.deso.org/dc4c15e4a32d7edd06658a5ffff45a3cb7e47d02304c43e7497b05f7e2ee7782.webp': { PostHashHex: '215fba4d74c9de92f93e7d649c7fc6ec5e0b3e1588736677a6ae55e1336ed843' },
              'https://images.deso.org/169d41d2de1b2ee0281f39cc69189e0c3561592ba90eac0f3948876b9c511859.webp': { PostHashHex: 'b967061ac3ee02612585d3925427084192f0114aa3145312aa51bfa1a4772e94' },
              'https://images.deso.org/aa6a5252345db1d69e5b5815208e4606bb1bf9c4c4e056e8a0877dbe33357e4f.webp': { PostHashHex: '049614cb2100ad8941435c7dbde8294837735f4a315e2665dd923b3fbfd45390' },
            },
          },*/
          4: {
            type: 'profile',
          },
          /*4: {
            type: 'embed',
            embedUrl: 'https://onedrive.live.com/embed?resid=F679F13D5B5C50E5%21581207&authkey=%21ACUtN4sYzM1xpMM&width=3812&height=2860',
          },*/
        }
      }
    },
    "Goldberry": {
      TransactorPublicKeyBase58Check: null,
      TargetUserPublicKeyBase58Check: null,
      AppPublicKeyBase58Check: null,
      AssociationType: null, // Indicate default association
      AssociationValue: null, // Indicate default value
      ExtraData: {
        palette: {
          backgroundColor: '#0b3240',
  
          /* Style sets */
          backgroundColorBody: '#0b3240',
          bodyColor: '#ffffff',
          bodyFont: null,
  
          backgroundColorSecondary: '#000000',
          
          backgroundColorTertiary: '#ffffff',
  
          /* header */
          headerColor: '#fbc968',
          headerBackgroundColor: '#0b3937',
          headerImageReflect: true,
          headerBackgroundGradient: true,
  
          /* effects */
          effectGlass: true,
          effectRounded: true,
          effectShadow: true,
  
          /* page accents */
          border: '#000000',
          borderSecondary: null,
          borderTertiary: null,
  
          links: null,
  
          tabColor: null,
          tabColorActive: '#3058b8',
  
          headingColor: null,
          headingFont: null,
  
          textAlignProfile: 'center',
          bannerAspect: null,
          imageAspect: null,
          videoAspect: null,
          backgroundImage: null, // uploaded via the usual API
        },
        defaultTab: 'posts',
        tabs: {
          'posts': { active: true, view: 'list' },
          'blog': { active: true, title: "Blog", isFeed: true, feedType: 'list', filter: { isBlogPost: true} },
          'nft': { active: true, title: "NFT", isFeed: true, feedType: 'nft', view: 'media', filter: { isNft: true } },
          'music': { active: true, title: "Music", isFeed: true, feedType: 'music', view: 'media', filter: { embedVideoUrl: true, embedUrlFilter: ["mousai","spotify"] } },
        },
        modules: {
          /*0: {
            type: 'text',
            content: 'Custom Text Block, with example embed below.',
          },*/
          1: {
            type: 'embed',
            nature: 'audio',
            embedUrl: 'https://open.spotify.com/track/2JkficY161HhO7nMRg2svL?si=bcc54ef18a68458a',
          },
          2: {
            type: 'pinned',
            count: 3,
          },
          /*
          3: {
            type: 'carousel', // add gallery for grid too
            nature: 'media',
            images: {
              'https://images.deso.org/4cb01ffcae9eaf6d0ec842d3312e2992e75ed254db19a29aeef8a788ea2ce3e5.webp': { PostHashHex: '5563cfaa85aba5d4edb84ae0c872fe8201a196b6c53ab3c654d771aed24d5b92' },
              'https://images.deso.org/e07acf50be0064861643b52a95170ce21184507b91eb3a785929aaf7c80fda1e.webp': { PostHashHex: '76bd20c672f7c9d7de8ed1c92a25f2031a9c2a02394d8b54d7fc03970d0bb798' },
              'https://images.deso.org/dc4c15e4a32d7edd06658a5ffff45a3cb7e47d02304c43e7497b05f7e2ee7782.webp': { PostHashHex: '215fba4d74c9de92f93e7d649c7fc6ec5e0b3e1588736677a6ae55e1336ed843' },
              'https://images.deso.org/169d41d2de1b2ee0281f39cc69189e0c3561592ba90eac0f3948876b9c511859.webp': { PostHashHex: 'b967061ac3ee02612585d3925427084192f0114aa3145312aa51bfa1a4772e94' },
              'https://images.deso.org/aa6a5252345db1d69e5b5815208e4606bb1bf9c4c4e056e8a0877dbe33357e4f.webp': { PostHashHex: '049614cb2100ad8941435c7dbde8294837735f4a315e2665dd923b3fbfd45390' },
            },
          },*/
          4: {
            type: 'profile',
          },
          /*4: {
            type: 'embed',
            embedUrl: 'https://onedrive.live.com/embed?resid=F679F13D5B5C50E5%21581207&authkey=%21ACUtN4sYzM1xpMM&width=3812&height=2860',
          },*/
        }
      }
    },
    "paulmp": {
      TransactorPublicKeyBase58Check: null,
      TargetUserPublicKeyBase58Check: null,
      AppPublicKeyBase58Check: null,
      AssociationType: null, // Indicate default association
      AssociationValue: null, // Indicate default value
      ExtraData: {
        palette: {
          backgroundColor: '#5f1a2f',
          backgroundColorBody: '#5f1a2f',
          backgroundColorSecondary: '#5f1a2f',
          backgroundColorTertiary: null,
  
          /* header */
          headerColor: '#efefef',
          headerBackgroundColor: '#000000',
          headerImageReflect: true,
          headerBackgroundGradient: true,
  
          /* effects */
          effectGlass: true,
          effectRounded: true,
          effectShadow: true,
  
          /* page accents */
          border: '#000000',
          borderSecondary: null,
          borderTertiary: null,
  
          links: null,
  
          tabColor: null,
          tabColorActive: '#3058b8',
  
          bodyColor: '#ffffff',
          bodyFont: null,
  
          headingColor: null,
          headingFont: null,
  
          textAlignProfile: 'center',
          bannerAspect: null,
          imageAspect: null,
          videoAspect: null,
          backgroundImage: null, // uploaded via the usual API
        },
        defaultTab: 'photography',
        tabs: {
          'posts': { active: true, view: 'list' },
          'blog': { active: true, title: "Blog", isFeed: true, view: 'list', feedType: 'list', filter: { isBlogPost: true} },
          'nft': { active: true, title: "NFT", isFeed: true, view: 'media', feedType: 'media', filter: { isNft: true } },
          'photography': { active: true, title: "Photography", isFeed: true, view: 'grid', feedType: 'media', filter: { tabSearch: 'photo', parentPostHash: false, imageUrls: true } }
          //'nft': { active: true, title: "NFT", isFeed: true, feedType: 'media', filter: { isBlogPost: true } },
          //'music': { active: true, title: "Music", isFeed: true, feedType: 'music', filter: { embedUrlFilter: ["mousai","spotify"] } },
        },
        modules: {
          /*0: {
            type: 'text',
            content: 'Custom Text Block, with example embed below.',
          },
          1: {
            type: 'embed',
            nature: 'audio',
            embedUrl: 'https://open.spotify.com/track/2JkficY161HhO7nMRg2svL?si=bcc54ef18a68458a',
          },*/
          0: {
            type: 'profile',
          },
          2: {
            type: 'pinned',
            count: 3,
          },
          3: {
            type: 'carousel', // add gallery for grid too
            nature: 'media',
            images: {
              'https://images.deso.org/0fd53c831062c52a23df0811aaf2478708b2d8f4bb51884d97c6697370415461.webp': { PostHashHex: '480318a8ebeab613ae9580e3c381bc0bc43227bf65d23521286c3ef9f4c70fe4' },
              'https://images.deso.org/77166ffdf1b249aca27ede90f8735192b3cb649e6268c0b9affce44049a4c275.webp': { PostHashHex: '1bdc77c5394d53392057abdbc3591e1a03ec2fad6baf3eef5504f059323f9cc8' },
              'https://images.deso.org/067db8d8785c864fde0ec496b108be351bf29f0777868dfca14d58b078cbe518.webp': { PostHashHex: 'd8f5bb4e75a4e57c565187658112006c7de23ee0e101b7ea8672460aafd45de2' },
              'https://images.deso.org/28c07bdca1695d6e96be439179f91493ec62b319009fe824d8a7c2dd1dbb612b.webp': { PostHashHex: 'e59823744a8285e549ae83de7a3f9d17b59fd13497c7072ea1edbfcd396fa509' },
              'https://images.deso.org/8aa09e6e4b2da6a7777a7db98a718c67689f33b142479ea46435ae9ebbdb7313.webp': { PostHashHex: '1e86572a26476b0f4f9c943c358522a2c64d4c6ca206ecdf72ce958f80667d92' },
              'https://images.deso.org/71c41ea7033e9cf53163e54c5d7a98b4ca5e4e7b9ba4644b34692df1a5dded62.webp': { PostHashHex: '5e23830b13057283eae95df583104362eb624854442bd51a13e12460fd980722' },
              'https://images.deso.org/063877f68f6df9b4eb0139623819b64aac5fc702bbe711740871deadcf711ea8.webp': { PostHashHex: 'bccbff0539605e551f2d638ffb0b72b2c4bf0eca315b167d01acd8fa41c664ab' },
              'https://images.deso.org/f9755eb1ffe76138a265376435dc95ce54ad68c66fcd166a7d4489c88f37f7d5.webp': { PostHashHex: 'a0746e6574c97e02cdf8f76e7deed2d72834e4be2a54ae24dc595d023bbae554' },
              'https://images.deso.org/6fe2db3a044470d7459fb5e66d5c2b2d59a8fb229e619cd92699188d014027c6.webp': { PostHashHex: '2980cf8a82d8b0c2ce2fb3971e8effbe2acfcf7124587e9e44a8a1a5c2d03168' },
              'https://images.deso.org/4cf9238e8d44df85a17b8b69bd74e0a8f50a38052cd5c66460d4f262f1d08f1b.webp': { PostHashHex: '69685e19511ce1a6a67f852915abce80bbf537f49c155a42ebd5b3afc6296e4d' },
            },
          },
          /*4: {
            type: 'embed',
            embedUrl: 'https://onedrive.live.com/embed?resid=F679F13D5B5C50E5%21581207&authkey=%21ACUtN4sYzM1xpMM&width=3812&height=2860',
          },*/
        }
      }
    },
    "Moggel": {
      TransactorPublicKeyBase58Check: null,
      TargetUserPublicKeyBase58Check: null,
      AppPublicKeyBase58Check: null,
      AssociationType: null, // Indicate default association
      AssociationValue: null, // Indicate default value
      ExtraData: {
        palette: {
          backgroundColor: '#efefef',
          backgroundColorBody: '#ffffff',
          backgroundColorSecondary: '#eeeeee',
          backgroundColorTertiary: '#dddddd',
  
          /* header */
          headerColor: '#333',
          headerBackgroundColor: '#ffffff',
          headerImageReflect: true,
          headerBackgroundGradient: true,
  
          /* effects */
          effectGlass: false,
          effectRounded: true,
          effectShadow: true,
  
          /* page accents */
          border: '#000000',
          borderSecondary: null,
          borderTertiary: null,
  
          links: null,
  
          tabColor: null,
          tabColorActive: '#555555',
          tabColorActiveText: '#ffffff',
  
          bodyColor: '#000000',
          bodyFont: `'Apple Chancery',cursive`,

          links: '#1111aa',
  
          headingColor: '#000000',
          headingFont: null,
  
          textAlignProfile: 'center',
          bannerAspect: null,
          imageAspect: null,
          videoAspect: null,
          backgroundImage: null, // uploaded via the usual API
        },
        defaultTab: 'photography',
        tabs: {
          'cartoon': { active: true, title: "Cartoon", isFeed: true, view: 'list', feedType: 'list', filter: { blog: true} },
          'posts': { active: true, view: 'list' },
          'images': { active: true, title: "Images", isFeed: true, view: 'grid', feedType: 'media', filter: { parentPostHash: false, imageUrls: true } }
          //'nft': { active: true, title: "NFT", isFeed: true, feedType: 'media', filter: { isBlogPost: true } },
          //'music': { active: true, title: "Music", isFeed: true, feedType: 'music', filter: { embedUrlFilter: ["mousai","spotify"] } },
        },
        modules: {
          /*0: {
            type: 'text',
            content: 'Custom Text Block, with example embed below.',
          },
          1: {
            type: 'embed',
            nature: 'audio',
            embedUrl: 'https://open.spotify.com/track/2JkficY161HhO7nMRg2svL?si=bcc54ef18a68458a',
          },*/
          0: {
            type: 'profile',
          },
          2: {
            type: 'pinned',
            count: 3,
          },
          3: {
            type: 'carousel', // add gallery for grid too
            nature: 'media',
            images: {
              'https://images.deso.org/0fd53c831062c52a23df0811aaf2478708b2d8f4bb51884d97c6697370415461.webp': { PostHashHex: '480318a8ebeab613ae9580e3c381bc0bc43227bf65d23521286c3ef9f4c70fe4' },
              'https://images.deso.org/77166ffdf1b249aca27ede90f8735192b3cb649e6268c0b9affce44049a4c275.webp': { PostHashHex: '1bdc77c5394d53392057abdbc3591e1a03ec2fad6baf3eef5504f059323f9cc8' },
              'https://images.deso.org/067db8d8785c864fde0ec496b108be351bf29f0777868dfca14d58b078cbe518.webp': { PostHashHex: 'd8f5bb4e75a4e57c565187658112006c7de23ee0e101b7ea8672460aafd45de2' },
              'https://images.deso.org/28c07bdca1695d6e96be439179f91493ec62b319009fe824d8a7c2dd1dbb612b.webp': { PostHashHex: 'e59823744a8285e549ae83de7a3f9d17b59fd13497c7072ea1edbfcd396fa509' },
              'https://images.deso.org/8aa09e6e4b2da6a7777a7db98a718c67689f33b142479ea46435ae9ebbdb7313.webp': { PostHashHex: '1e86572a26476b0f4f9c943c358522a2c64d4c6ca206ecdf72ce958f80667d92' },
              'https://images.deso.org/71c41ea7033e9cf53163e54c5d7a98b4ca5e4e7b9ba4644b34692df1a5dded62.webp': { PostHashHex: '5e23830b13057283eae95df583104362eb624854442bd51a13e12460fd980722' },
              'https://images.deso.org/063877f68f6df9b4eb0139623819b64aac5fc702bbe711740871deadcf711ea8.webp': { PostHashHex: 'bccbff0539605e551f2d638ffb0b72b2c4bf0eca315b167d01acd8fa41c664ab' },
              'https://images.deso.org/f9755eb1ffe76138a265376435dc95ce54ad68c66fcd166a7d4489c88f37f7d5.webp': { PostHashHex: 'a0746e6574c97e02cdf8f76e7deed2d72834e4be2a54ae24dc595d023bbae554' },
              'https://images.deso.org/6fe2db3a044470d7459fb5e66d5c2b2d59a8fb229e619cd92699188d014027c6.webp': { PostHashHex: '2980cf8a82d8b0c2ce2fb3971e8effbe2acfcf7124587e9e44a8a1a5c2d03168' },
              'https://images.deso.org/4cf9238e8d44df85a17b8b69bd74e0a8f50a38052cd5c66460d4f262f1d08f1b.webp': { PostHashHex: '69685e19511ce1a6a67f852915abce80bbf537f49c155a42ebd5b3afc6296e4d' },
            },
          },
          /*4: {
            type: 'embed',
            embedUrl: 'https://onedrive.live.com/embed?resid=F679F13D5B5C50E5%21581207&authkey=%21ACUtN4sYzM1xpMM&width=3812&height=2860',
          },*/
        }
      }
    }
  };
  
  export const getCssProperty = (elementSelector, property) => {
    const element = document.querySelector(elementSelector);
  
    if (!element) {
      console.error(`Element with selector '${elementSelector}' not found.`);
      return null; // Return null or handle appropriately if element is not found
    }
  
    //console.log("[customCss] getCssProperty: ", element, property);
    const styles = window.getComputedStyle(element);
    //console.log("[customCss] style: ", styles[property], styles);
    return styles.getPropertyValue(property); // Use getPropertyValue to retrieve the CSS property value
  };
  
  export const getCssDeclaration = (selector, property) => {
    // Loop through all stylesheets
    for (const styleSheet of document.styleSheets) {
      try {
        // Loop through all rules in the stylesheet
        for (const rule of styleSheet.cssRules) {
          // Check if the rule is a CSSStyleRule and matches the selector
          if (rule instanceof CSSStyleRule && rule.selectorText === selector) {
            // Return the value of the specified property
            return rule.style.getPropertyValue(property);
          }
        }
      } catch (error) {
        // Handle any errors that may occur while accessing rules
        console.error('Error accessing stylesheet rules:', error);
      }
    }
  
    // Return null if the declaration is not found
    return null;
  };
  
  export const isValidHexColor = (color) => /^#([0-9A-F]{3}){1,2}$/i.test(color);
  
  export const parseColor = (color) => {
    if (isValidHexColor(color)) {
        return {
            r: parseInt(color.substring(1, 3), 16),
            g: parseInt(color.substring(3, 5), 16),
            b: parseInt(color.substring(5, 7), 16)
        };
    }
    return null;
  };
  
  export const setCustomCss = (setCssInjected, cssInjected, profile, customisation, showCustomisation, setActiveTab) => {
    //console.log("[profiles.js] setCustomCss evoked: css, showCustomisation", cssInjected, showCustomisation);
    if (customisation !== null && showCustomisation === true) {
      const palette = customisation?.ExtraData?.palette || {};
  
      if(customisation?.defaultTab) { setActiveTab(customisation?.defaultTab); }
      let headerImageSrc;
      if (profile.extraData && profile.extraData.FeaturedImageURL) {
        // Featured Image in Profile
        headerImageSrc = profile.extraData.FeaturedImageURL;
      };
      const customCSS = `
          .custom {
                ${palette.bodyColor ? `color: ${palette.bodyColor} !important;` : ``}
                ${palette.bodyFont ? `font-family: ${palette.bodyFont} !important;` : ``}
                ${palette.backgroundImage ? `
                    background-image: url(${palette.backgroundImage}) !important;
                    ${palette.backgroundRepeat ? `background-repeat: repeat !important;` : `background-repeat: no-repeat !important;`}
                    ${palette.backgroundSize ? `background-size: contain !important;` : ``}
                    ${palette.backgroundAttachment ? `background-attachment: fixed !important;` : `` }
                    background-position: top center !important;
                    position: relative; /* Ensure stacking context */
                    z-index: 0; /* Ensure the background image is behind content */
                    ${palette.backgroundColor ? `background-color: rgba(${parseInt(palette.backgroundColor.substring(1, 3), 16)}, ${parseInt(palette.backgroundColor.substring(3, 5), 16)}, ${parseInt(palette.backgroundColor.substring(5, 7), 16)}, 0.5)` : `` }
                ` : `${palette.backgroundColor ? `background-color: ${palette.backgroundColor} !important;` : ``}` }
            }

          .custom .profile-header-background {
            ${palette.backgroundImage ? `
                ${palette.headerBackgroundColor ? `background-color: rgba(${parseInt(palette.headerBackgroundColor.substring(1, 3), 16)}, ${parseInt(palette.headerBackgroundColor.substring(3, 5), 16)}, ${parseInt(palette.headerBackgroundColor.substring(5, 7), 16)}, 0.5);` : `` }
            ` : `${palette.headerBackgroundColor ? `background-color: ${palette.headerBackgroundColor} !important;` : ``}` }
            ${palette.headerColor ? (
              `color: ${palette.headerColor} !important;`
            ) : ''}
          }
  
          .custom .profileContent {
            ${palette.textAlignProfile ? `text-align: ${palette.textAlignProfile} !important;` : ``}
          }
  
          .custom .border-left, .custom .border-right, .custom .border-top, .custom .border-bottom {
            ${palette.border ? `border-color: ${palette.border} !important;` : ``}
            ${palette.bodyColor ? `color: ${palette.bodyColor} !important;` : ``}
          }
  
          .custom .bg-body, .custom .bg-body .form-control, .custom .card {
            ${palette.backgroundColorBody ? `
                ${palette.effectGlass ? `background-color: rgba(${parseInt(palette.backgroundColorBody.substring(1, 3), 16)}, ${parseInt(palette.backgroundColorBody.substring(3, 5), 16)}, ${parseInt(palette.backgroundColorBody.substring(5, 7), 16)}, 0.2) !important;`
                    : `background-color: ${palette.backgroundColorBody} !important;`}
            ` : ``}
            ${palette.bodyColor ? `color: ${palette.bodyColor} !important;` : ``}
            ${palette.bodyFont ? `font-family: ${palette.bodyFont} !important;` : ``}
            ${palette.border ? `border-color: ${palette.border} !important;` : ``}
          }

          .custom .card-primary {
            ${palette.bodyColor ? `color: ${palette.bodyColor} !important;` : ``}
            ${palette.border ? `border-color: ${palette.border} !important; border-width: 1px !important;` : ``}
          }
          
          .custom .card-secondary {
            ${palette.backgroundColorSecondary ? `
                ${palette.effectGlass ? `background-color: rgba(${parseInt(palette.backgroundColorSecondary.substring(1, 3), 16)}, ${parseInt(palette.backgroundColorSecondary.substring(3, 5), 16)}, ${parseInt(palette.backgroundColorSecondary.substring(5, 7), 16)}, 0.2) !important;`
                    : `background-color: ${palette.backgroundColorSecondary} !important;`}
            ` : ``}
            ${palette.borderSecondary ? `border-color: ${palette.borderSecondary} !important; border-width: 1px;` : ``}
          }

          .custom .card-tertiary {
            ${palette.backgroundColorTertiary ? `
              ${palette.effectGlass ? `background-color: rgba(${parseInt(palette.backgroundColorTertiary.substring(1, 3), 16)}, ${parseInt(palette.backgroundColorTertiary.substring(3, 5), 16)}, ${parseInt(palette.backgroundColorTertiary.substring(5, 7), 16)}, 0.2) !important;`
                  : `background-color: ${palette.backgroundColorTertiary} !important;`}
          ` : ``}
          ${palette.borderTertiary ? `border-color: ${palette.borderTertiary} !important; border-width: 1px;` : ``}
          }

          .custom .overlay {
            ${palette.headerBackgroundColor ? `
                ${palette.effectGlass ? `background-color: rgba(${parseInt(palette.headerBackgroundColor.substring(1, 3), 16)}, ${parseInt(palette.headerBackgroundColor.substring(3, 5), 16)}, ${parseInt(palette.headerBackgroundColor.substring(5, 7), 16)}, 0.2) !important;`
                    : `background-color: ${palette.headerBackgroundColor} !important;`}
            ` : ``}
          }
          
  
          .custom .nav-tabs {
            ${palette.border ? `border-bottom: 1px solid ${palette.border} !important;` : ``}
          }
  
          .custom .text-body {
            ${palette.bodyColor ? (
              `color: ${palette.bodyColor} !important;`
            ) : ''}
          }
  
          .custom .text-muted {
            ${palette.bodyColor ? (
              `color: rgba(${parseInt(palette.bodyColor.substring(1, 3), 16)}, ${parseInt(palette.bodyColor.substring(3, 5), 16)}, ${parseInt(palette.bodyColor.substring(5, 7), 16)}, 0.75) !important;`
            ) : ''}
          }      
            
          .custom a.postBodyLink, .custom .nav-link.active {
            ${palette.bodyColor ? (
              `color: ${palette.bodyColor} !important;`
            ) : ''}
          }
  
          .custom .profile-page-content {
            ${palette.backgroundImage ? `
                ${palette.headerBackgroundColor && palette.backgroundColor && palette.headerBackgroundGradient ? `
                    background: linear-gradient(
                        to bottom,
                        rgba(${parseInt(palette.headerBackgroundColor.substring(1, 3), 16)}, ${parseInt(palette.headerBackgroundColor.substring(3, 5), 16)}, ${parseInt(palette.headerBackgroundColor.substring(5, 7), 16)}, 0.5) 0%,
                        rgba(${parseInt(palette.backgroundColor.substring(1, 3), 16)}, ${parseInt(palette.backgroundColor.substring(3, 5), 16)}, ${parseInt(palette.backgroundColor.substring(5, 7), 16)}, 0.5) calc(18rem),
                        rgba(${parseInt(palette.backgroundColor.substring(1, 3), 16)}, ${parseInt(palette.backgroundColor.substring(3, 5), 16)}, ${parseInt(palette.backgroundColor.substring(5, 7), 16)}, 0) 100%
                    ) !important;` : ''}
                ` : `
                ${palette.headerBackgroundColor && palette.backgroundColor && palette.headerBackgroundGradient ? `
                    background: linear-gradient(
                        to bottom,
                        ${palette.headerBackgroundColor} 0%,
                        rgba(${parseInt(palette.backgroundColor.substring(1, 3), 16)}, ${parseInt(palette.backgroundColor.substring(3, 5), 16)}, ${parseInt(palette.backgroundColor.substring(5, 7), 16)}, 1) calc(18rem),
                        rgba(${parseInt(palette.backgroundColor.substring(1, 3), 16)}, ${parseInt(palette.backgroundColor.substring(3, 5), 16)}, ${parseInt(palette.backgroundColor.substring(5, 7), 16)}, 0) 100%
                    ) !important;` : ''}
                ` }
              ${palette.bodyColor ? `color: ${palette.bodyColor} !important;` : ``}
              height: 100%;
              background-attachment: scroll !important; /* Make the background static */
          }
  
          ${palette.headerImageReflect && headerImageSrc ? `
            .custom .effect-reflect-container::after {
                background-image: url(${headerImageSrc});
            }
        ` : ``}
        
  
          
          .custom .bg-body-secondary {
            ${palette.backgroundColorSecondary ? `
                ${palette.effectGlass ? `background-color: rgba(${parseInt(palette.backgroundColorSecondary.substring(1, 3), 16)}, ${parseInt(palette.backgroundColorSecondary.substring(3, 5), 16)}, ${parseInt(palette.backgroundColorSecondary.substring(5, 7), 16)}, 0.2) !important;`
                    : `background-color: ${palette.backgroundColorSecondary} !important;`}
            ` : ``}
            ${palette.borderSecondary ? `border-color: ${palette.borderSecondary} !important;` : ``}
            border-width: 1px;
          }
  
          .custom .bg-body-tertiary {
            ${palette.backgroundColorTertiary ? `
                ${palette.effectGlass ? `background-color: rgba(${parseInt(palette.backgroundColorTertiary.substring(1, 3), 16)}, ${parseInt(palette.backgroundColorTertiary.substring(3, 5), 16)}, ${parseInt(palette.backgroundColorTertiary.substring(5, 7), 16)}, 0.2) !important;`
                    : `background-color: ${palette.backgroundColorTertiary} !important;`}
            ` : ``}
            ${palette.borderTertiary ? `border-color: ${palette.borderTertiary} !important;` : ``}
          }
      
          /* Nested styles for headings */
          .custom h1, .custom h2, .custom h3, .custom h4, .custom h5, .custom h6,
          .custom-secondary h1, .custom-secondary h2, .custom-secondary h3, .custom-secondary h4,
          .custom-secondary h5, .custom-secondary h6,
          .custom .btn {
            ${palette.headingColor ? `color: ${palette.headingColor} !important;` : ``}
            ${palette.headingFont ? `font-family: ${palette.headingFont} !important;` : ``}
          }
      
          .custom .border {
            ${palette.border ? `border: ${palette.border};` : ``}
          }
      
          .custom a {
            ${palette.links ? `color: ${palette.links};` : ``}
          }
  
          .custom .nav-link {
            ${palette.headerColor ? (
              `color: rgba(${parseInt(palette.headerColor.substring(1, 3), 16)}, ${parseInt(palette.headerColor.substring(3, 5), 16)}, ${parseInt(palette.headerColor.substring(5, 7), 16)}, 0.75) !important;`
            ) : ''}
          }
      
          .custom .nav-pills .nav-item .nav-link.active {
            ${palette.tabColorActive ? `background-color: rgba(${parseInt(palette.tabColorActive.substring(1, 3), 16)}, ${parseInt(palette.tabColorActive.substring(3, 5), 16)}, ${parseInt(palette.tabColorActive.substring(5, 7), 16)}, 0.75) !important;` : `` }
            ${palette.tabActiveBorder ? `border-color: ${palette.tabActiveBorder};` : ``}
            ${palette.tabColorActiveText ? (
              `color: rgba(${parseInt(palette.tabColorActiveText.substring(1, 3), 16)}, ${parseInt(palette.tabColorActiveText.substring(3, 5), 16)}, ${parseInt(palette.tabColorActiveText.substring(5, 7), 16)}, 0.75) !important;`
            ) : ''}
          }

          ${palette.tabColor && (`
          .custom .nav-pills .nav-item .nav-link, .custom .contentBar {
            background-color: rgba(${parseInt(palette.tabColor.substring(1, 3), 16)}, ${parseInt(palette.tabColor.substring(3, 5), 16)}, ${parseInt(palette.tabColor.substring(5, 7), 16)}, 0.75) !important;

          }
          `)}
  
          ${palette.effectRounded ? (`
          .custom .nav-pills .nav-link, .custom .card-effect, .custom .overlay, .custom .contentBar {
            border-radius: 1em !important;
          }
          .card-effect > :last-child {
            border-radius: none !important;
            border-bottom-left-radius: 1em !important;
            border-bottom-right-radius: 1em !important;
            overflow: hidden;
          }
          .post_id > img {
            border-radius: 1em !important;
          }
          `) : `` }
  
          ${palette.effectGlass ? (`
          .custom .nav-pills .nav-link.active, .custom .card-effect, .custom .overlay, .custom .bg-body, .custom .contentBar {
            backdrop-filter: blur(5px) !important; 
            -webkit-backdrop-filter: blur(5px) !important;
          }
          `) : `` }
  
          ${palette.effectShadow ? (`
          .custom .nav-pills .nav-link.active, .custom .card-effect {
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); 
            -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
          }
          `) : `` }
  
          .custom .profile-header-image img.feature {
            ${palette.bannerAspect ? `aspect-ratio: ${palette.bannerAspect}` : null };
            width: 100%;
            object-fit: cover;
            object-position: center;
          }
  
          .custom .imageAspect {
            ${palette.imageAspect ? `aspect-ratio: ${palette.imageAspect} !important;` : ``}
            width: 100%;
            object-fit: cover;
            object-position: center;
          }
  
          .custom .videoAspect {
            ${palette.videoAspect ? `aspect-ratio: ${palette.videoAspect} !important;` : ``}
            width: 100%;
            object-fit: cover;
            object-position: center;
          }
        `;
  
      // Remove any existing custom styles before adding new ones
      const existingStyleTags = document.querySelectorAll('.custom-style-tag');
      existingStyleTags.forEach(tag => tag.parentNode.removeChild(tag));
  
      const styleTag = document.createElement('style');
      styleTag.classList.add('custom-style-tag');
      styleTag.appendChild(document.createTextNode(customCSS));
  
      // Insert the style tag at the very start of the page header
      const firstStyleTag = document.querySelector('style');
      if (firstStyleTag) {
          document.head.insertBefore(styleTag, firstStyleTag);
      } else {
          document.head.appendChild(styleTag);
      }
  
      setCssInjected(true);
  
      // Cleanup function to remove injected style tag
      return () => {
        const styleTagsToRemove = document.querySelectorAll('.custom-style-tag');
        styleTagsToRemove.forEach(styleTagToRemove => {
            styleTagToRemove.parentNode.removeChild(styleTagToRemove);
        });
        setCssInjected(false);
      };
    } else {
      const styleTagsToRemove = document.querySelectorAll('.custom-style-tag');
      if (styleTagsToRemove.length > 0) {
          //console.log("[profiles.js] setCustomCss - DISABLED...");
          styleTagsToRemove.forEach(styleTagToRemove => {
              styleTagToRemove.parentNode.removeChild(styleTagToRemove);
          });
          setCssInjected(false);
      }
    }
  };