import React, { useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom';
import { SendDiamondsButton, SendReactionButton } from "./transactions";
import { Link } from "react-router-dom";
import { Avatar } from "./layouts";
import { Carousel } from "react-bootstrap";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

export const PostAttachments = ({ post, currentUser, level, type, view, accessGroups, preferences, thread, preview, alternateUsers, isExpanded }) => {
    const postAttachments = [];
    const musicAttachments = [];

    const hasAttachments = (post) => {
        return post.ImageURLs || post.VideoURLs;
      };
      
    const mediaAttachments = hasAttachments(post) ? <PostMedia post={post} currentUser={currentUser} view={view} /> : null;

    if (mediaAttachments) {
        postAttachments.push(mediaAttachments);
    }

    if (post.PostExtraData && post.PostExtraData.EmbedVideoURL && post.PostExtraData.EmbedVideoURL !== '') {

        if(post.PostExtraData.EmbedVideoURL.includes("spotify.com")) {
            // spotify embed
            musicAttachments.push(post.PostExtraData.EmbedVideoURL);
            //console.log("[posts.js] spotify embed, post type:", type);
            postAttachments.push(
                <iframe 
                    style={{ borderRadius: "12px", margin: "0", height: view === "media" ? "352px" : view === "grid" ? "150px" : "152px" }}
                    src={`${post.PostExtraData.EmbedVideoURL}`}
                    width="100%" 
                    frameBorder="0" 
                    allowFullScreen="" 
                    className='m-0 mt-2 p-0 postAttachment'
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                    title={`Embed from ${post.ProfileEntryResponse && post.ProfileEntryResponse.Username ? post.ProfileEntryResponse.Username: `Author`}'s post: ${post.PostHash}`}
                    loading="lazy" 
                />
                
            );
        } else if(post.PostExtraData.EmbedVideoURL.includes("https://mousai.stream")) {
            musicAttachments.push(post.PostExtraData.EmbedVideoURL);
            postAttachments.push(
                <iframe 
                    width="100%" 
                    style={{ margin: "0", padding: "0" }}
                    height="165px" 
                    scrolling="no" 
                    frameBorder="no" 
                    className='m-0 p-0 mt-2 p-0 postAttachment'
                    allow="autoplay" 
                    src={`${post.PostExtraData.EmbedVideoURL}`}
                    loading="lazy"
                />
            );
        } else if (post.PostExtraData.EmbedVideoURL.includes("giphy.com")) {
            function extractGiphyID(url) {
                // Define regex patterns to match different Giphy URL formats
                const patterns = [
                    /giphy\.com\/media\/([a-zA-Z0-9]+)/,   // Matches URLs like https://giphy.com/media/ID/
                    /giphy\.com\/gifs\/([a-zA-Z0-9]+)/,    // Matches URLs like https://giphy.com/gifs/ID/
                    /giphy\.com\/embed\/([a-zA-Z0-9]+)/    // Matches URLs like https://giphy.com/embed/ID/
                ];
        
                // Try each pattern to find a match
                for (let pattern of patterns) {
                    const match = url.match(pattern);
                    if (match) {
                        return match[1];
                    }
                }
                return null;
            }
        
            const giphyID = extractGiphyID(post.PostExtraData.EmbedVideoURL);
            if (giphyID) {
                const giphyEmbedURL = `https://giphy.com/embed/${giphyID}`;
        
                postAttachments.push(
                    <div className={`p-0 m-0 ratio ${view === 'grid' ? `ratio-1x1` : view === 'media' ? `ratio-4x3` : `ratio-16x9`}`} style={{ width: "100%", position: "relative" }}>
                        <iframe 
                            src={`${giphyEmbedURL}`}
                            width="100%" 
                            height="100%" 
                            style={{ position: "absolute", scrollBehavior: "hidden", background: "transparent", colorScheme: "auto" }}
                            className='giphy-embed m-0 p-0 postAttachment'
                            frameBorder="no"
                            allowFullScreen >
                        </iframe>
                    </div>
                );
            } else {
                console.warn("Unable to extract Giphy ID from URL:", post.PostExtraData.EmbedVideoURL);
            }
        } else if(post.PostExtraData.EmbedVideoURL.includes("https://www.youtube.com")) {
            // Function to extract video ID from YouTube URL
            const extractYouTubeID = (url) => {
                const regex = /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^&\n?#]+)/;
                const matches = url.match(regex);
                return matches ? matches[1] : null;
            };
        
            const videoID = extractYouTubeID(post.PostExtraData.EmbedVideoURL);
            if (videoID) {
                const privacyEnhancedURL = `https://www.youtube-nocookie.com/embed/${videoID}`;
                postAttachments.push(
                    <>
                        <div className={`m-0 p-0 ratio ${view === 'grid' ? `ratio-1x1` : view === 'media' ? `ratio-4x3` : `ratio-16x9`}`}>
                            <iframe 
                                frameBorder="0" 
                                allow="picture-in-picture; clipboard-write; encrypted-media; gyroscope; accelerometer; encrypted-media;" 
                                allowFullScreen="" 
                                className="mx-auto rounded-3 w-100 postAttachment" 
                                src={`${privacyEnhancedURL}?rel=0`}
                                style={{ marginTop: `-1px` }}
                                loading="lazy"
                                title={`Embed from ${post.ProfileEntryResponse && post.ProfileEntryResponse.Username ? post.ProfileEntryResponse.Username : `Author`}'s post: ${post.PostHash}`}
                            />
                        </div>
                    </>
                );
            } else {
                // Handle invalid YouTube URL case
                postAttachments.push(<div>Invalid YouTube URL</div>);
            }
        } else if(post.PostExtraData.EmbedVideoURL.includes("https://www.tiktok.com")) {
            postAttachments.push(
                <iframe 
                    //id="embed-iframe" 
                    frameBorder="0" 
                    allow="picture-in-picture; clipboard-write; encrypted-media; gyroscope; accelerometer; encrypted-media;" 
                    allowFullScreen="" 
                    className="m-0 p-0 rounded-3" 
                    height="738" 
                    src={`${post.PostExtraData.EmbedVideoURL}&transparent=1`}
                    style={{ marginTop: `-1px` }}
                    //style={{ maxWidth: `325px` }}
                    loading="lazy"
                    title={`Embed from ${post.ProfileEntryResponse && post.ProfileEntryResponse.Username ? post.ProfileEntryResponse.Username: `Author`}'s post: ${post.PostHash}`}
                />
            );
        } else if(post.PostExtraData.EmbedVideoURL.includes("https://twitter.com/")) {
            postAttachments.push(
                <TweetEmbed url={post.PostExtraData.EmbedVideoURL} preferences={preferences} />
            );
        } else {
            postAttachments.push(
                <>
                <div className="p-0 m-0" style={{ width: "100%", height: "0", paddingBottom: "100%", position: "relative" }}>
                    Other - URL: {post.PostExtraData.EmbedVideoURL}<br/>
                    <iframe 
                        src={`${post.PostExtraData.EmbedVideoURL}?transparent=1`}
                        width="100%" 
                        height="100%" 
                        style={{ position: "absolute", backgroundColor: "#000" }}
                        className='m-0 p-0'
                        frameBorder="no"
                        allowFullScreen >
                    </iframe>
                </div>
                </>
            );
        }
    }
    
    return (
        <div className="card-img-bottom m-0 p-0">
            {postAttachments.map((attachment, index) => (
                <React.Fragment key={index}>
                    {attachment}
                </React.Fragment>
            ))}
        </div>
    )
}


export const TweetEmbed = ({ url, preferences }) => {
    const tweetRef = useRef();
    const containerRef = useRef();
    const [showEmbed, setShowEmbed] = useState(false);
  
    const loadTweet = () => {
      loadTwitterScript()
        .then(() => {
          if (window.twttr && window.twttr.widgets) {
            window.twttr.widgets.load();
          }
        })
        .catch((error) => {
          console.error("Twitter script failed to load:", error);
        });
    };
  
    const onIntersection = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          loadTweet();
        }
      });
    };
  
    useEffect(() => {
      if(showEmbed) {
        const observer = new IntersectionObserver(onIntersection, {
          threshold: 0.1,
        });
        if (tweetRef.current) {
          observer.observe(tweetRef.current);
        }
        return () => {
          if (tweetRef.current) {
            observer.unobserve(tweetRef.current);
          }
        };
      } 
    }, [showEmbed]);
  
    return (
      <>
        <div className="w-100 text-center">
          <span className="btn border-0 text-center action"  onClick={() => setShowEmbed(!showEmbed)}>
            {showEmbed ? `hide` : `show`}
            <i className="bi bi-twitter-x mx-2"></i>
            embed
          </span><br/>
          {!showEmbed && (
            <div className="text-muted small pb-3"><i className="bi bi-info-circle-fill me-2"></i>you should be aware of <Link className="text-muted link-underline-secondary" to={`https://x.com/en/tos`} target="_blank">X's data tracking and terms and conditions</Link></div>
          )}
        </div>
        {showEmbed && (
          <div className="w-100 d-flex justify-content-center twitter" ref={tweetRef}>
            <blockquote id={containerRef} className="twitter-tweet collapse" data-theme={preferences?.theme}>
              <a href={url} style={{ background: "transparent", minHeight: "100px" }}></a>
            </blockquote>
          </div>
        )}
      </>
    );
  };

  const loadTwitterScript = () => {
    return new Promise((resolve) => {
      if (window.twttr) {
        resolve();
      } else {
        if (!document.querySelector('script[src="https://platform.twitter.com/widgets.js"]')) {
          const script = document.createElement('script');
          script.src = 'https://platform.twitter.com/widgets.js';
          script.async = true;
          script.defer = true;  // Add defer for faster parsing
          script.onload = () => {
            resolve();
          };
          document.body.appendChild(script);
        } else {
          resolve();
        }
      }
    });
  };




  export const PostMediaModal = ({ media, onClose, post, currentUser }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const maxHeight = window.innerHeight - 120;
    const handleSelect = (selectedIndex, e) => {
        setCurrentIndex(selectedIndex);
    };

    const handleOutsideClick = (e) => {
        // Check if the click occurred outside the modal content
        if (e.target.classList.contains('modal')) {
            onClose(); // Close the modal if the click is outside the modal content
        }
    };


    return ReactDOM.createPortal(
        <div>
            <div className="modal-backdrop show" style={{ display: 'block', zIndex: 20000 }}></div>
            <div className="modal w-100 h-100 fullscreen-carousel d-flex align-items-center justify-content-center" style={{ display: 'block', zIndex: 20010 }} onClick={handleOutsideClick}>
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content w-100 smooth">
                        <div className="position-absolute bottom-0 start-0 p-4 pb-5 w-100 d-flex flex-row flex-nowrap justify-content-between align-items-end" style={{ zIndex: 10 }}>
                            <div className="rounded-2 bg-body bg-opacity-50 d-flex flex-column align-items-center p-2">
                                <span className='mb-4'><SendDiamondsButton PostHashHex={post.PostHashHex} SenderPublicKeyBase58Check={currentUser?.PublicKeyBase58Check} ReceiverPublicKeyBase58Check={post.PosterPublicKeyBase58Check} DiamondLevel={1} post={post} currentUser={currentUser} /></span>
                                <span ><SendReactionButton PostHashHex={post.PostHashHex} SenderPublicKeyBase58Check={currentUser?.PublicKeyBase58Check} ReceiverPublicKeyBase58Check={post.PosterPublicKeyBase58Check} post={post} currentUser={currentUser} /></span>
                            </div>
                            <div className="rounded-2 bg-body bg-opacity-50 d-flex align-items-center p-2">
                                <Link className="text-body text-decoration-none" to={`/posts/${post.PostHashHex}`}>view post</Link>
                            </div>
                        </div>
                        <div className="w-100 position-absolute top-0 end-0 p-4 pt-5 d-flex flex-row flex-nowrap justify-content-between align-items-center" style={{ zIndex: 10 }}>
                            <div className="rounded-2 bg-body bg-opacity-50 d-flex align-items-center p-2">
                                <Avatar suppliedProfile={post.ProfileEntryResponse} type="avatar" publicKey={post.PosterPublicKeyBase58Check} />
                                &nbsp;<Avatar suppliedProfile={post.ProfileEntryResponse} type="username" publicKey={post.PosterPublicKeyBase58Check} />
                            </div>
                            <div className="rounded-2 bg-body bg-opacity-50 d-flex align-items-center">
                                <span 
                                    className="p-2 px-3 text-body" 
                                    onClick={onClose} // Call the onClose function when the button is clicked
                                    aria-label="Close"
                                    style={{ zIndex: 10 }} // Ensure the button is above other elements
                                >
                                    <i className="bi bi-x-lg"></i>
                                </span>
                            </div>
                        </div>
                        <div className="w-100 h-100 modal-body p-0" onClick={handleOutsideClick}>
                            <Carousel activeIndex={currentIndex} onSelect={handleSelect} controls={media.length > 1 ? true : false} indicators={false}>
                                {media.map((item, index) => (
                                    <Carousel.Item key={index}  onClick={onClose}>
                                        <TransformWrapper>
                                            <TransformComponent>
                                              
                                                {item}
                                              
                                            </TransformComponent>
                                        </TransformWrapper>
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        document.getElementById('modal-root')
    );
};

const extractVideoIdFromURL = (url) => {
    const match = url.match(/v=([^&]+)/);
    return match ? match[1] : null;
  };

export const PostMedia = ({ post, currentUser, view }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState(null);
  
    const openModal = (media) => {
      setSelectedMedia(media);
      setShowModal(true);
    };
  
    const closeModal = () => {
      setShowModal(false);
    };
  
    const postAttachments = [];
    let mediaInfo;
  
    if (post && post.PostExtraData && post.PostExtraData.mediaInfo) {
      mediaInfo = post.PostExtraData.mediaInfo;
    }
  
    if (post.ImageURLs && post.ImageURLs.length > 0 && post.ImageURLs !== '') {
      post.ImageURLs.forEach((ImageURL, index) => {
        if (ImageURL && ImageURL.trim() !== '') {
          const content = (
            <img
              key={`image-${index}`}
              src={ImageURL}
              className={`img-fluid w-100`}
              alt="Post Image"
              loading="lazy"
              onClick={() => openModal(post.ImageURLs.map(url => <img className='post-image img-fluid' key={url} src={url} alt="Post Image" />))}
            />
          );
          postAttachments.push({
            content: content,
            mediaInfo: mediaInfo && mediaInfo[ImageURL] ? mediaInfo[ImageURL] : null,
          });
        }
      });
    }
  
    if (post.VideoURLs && post.VideoURLs.length > 0 && post.VideoURLs !== '') {
      post.VideoURLs.forEach((VideoURL, index) => {
        if (VideoURL && VideoURL.trim() !== '') {
          if (VideoURL.includes("https://lvpr.tv")) {
            const content = (
              <div
                key={`video-${index}`}
                className="p-0 m-0 w-100"
                onClick={() => openModal(post.VideoURLs.map(url => (
                  <iframe
                    key={VideoURL}
                    title={`Video from ${post.ProfileEntryResponse && post.ProfileEntryResponse.Username ? post.ProfileEntryResponse.Username : `Author`}'s post: ${post.PostHashHex}`}
                    allow="fullscreen; picture-in-picture; autoplay; encrypted-media"
                    frameBorder="0"
                    className="img-fluid postAttachment"
                    src={`${url}&lowLatency=true&autoplay=false&muted=false`}
                    style={{ width: "100%" }}
                    loading="lazy"
                  />
                )))}
              >
                <iframe
                  title={`Video from ${post.ProfileEntryResponse && post.ProfileEntryResponse.Username ? post.ProfileEntryResponse.Username : `Author`}'s post: ${post.PostHashHex}`}
                  allow="fullscreen; picture-in-picture; autoplay; encrypted-media"
                  frameBorder="0"
                  className={`p-0 m-0 videoFeedAspect postAttachment`}
                  src={`${VideoURL}&lowLatency=true&autoplay=false&muted=false`}
                  style={{ width: "100%", height: "auto" }}
                  loading="lazy"
                />
              </div>
            );
            postAttachments.push({
              content: content,
              mediaInfo: mediaInfo && mediaInfo[VideoURL] ? mediaInfo[VideoURL] : null,
            });
          } else {
            const content = (
                <video controls className="w-100 m-0 p-0">
                    <source type="video/mp4" src={`${VideoURL}`} /> 
                    Your browser does not support the video tag. 
                </video>
            );
            {/*
            const content = (
              <div
                key={`video-${index}`}
                className={`ratio ${view === 'grid' ? `ratio-1x1` : view === 'media' ? `ratio-4x3` : `ratio-16x9`}`}
                onClick={() => openModal(post.VideoURLs.map(url => (
                  <iframe
                    key={url}
                    title={`Video from ${post.ProfileEntryResponse && post.ProfileEntryResponse.Username ? post.ProfileEntryResponse.Username : `Author`}'s post: ${post.PostHashHex}`}
                    allow="fullscreen; picture-in-picture; autoplay; encrypted-media"
                    frameBorder="0"
                    className="img-fluid postAttachment"
                    src={`${url}&lowLatency=true&autoplay=false&muted=true`}
                    style={{ width: "100%" }}
                  />
                )))}
              >
                <iframe
                  title={`Video from ${post.ProfileEntryResponse && post.ProfileEntryResponse.Username ? post.ProfileEntryResponse.Username : `Author`}'s post: ${post.PostHashHex}`}
                  allow="fullscreen; picture-in-picture; autoplay; encrypted-media"
                  frameBorder="0"
                  className="img-fluid postAttachment"
                  src={`${VideoURL}&lowLatency=true&autoplay=false&muted=true`}
                  style={{ width: "100%" }}
                />
              </div>
            );
            */}
            postAttachments.push({
              content: content,
              mediaInfo: mediaInfo && mediaInfo[VideoURL] ? mediaInfo[VideoURL] : null,
            });
          }
        }
      });
    }
  
    // If no attachments, return null
    if (postAttachments.length === 0) {
      //console.log('No attachments found');
      return null;
    }
  
    //console.log('Attachments found:', postAttachments);
  
    return (
      <>
        {postAttachments.length > 1 ? (
          <Carousel controls={postAttachments.length > 1} indicators={false} slide={true}>
            {postAttachments.map((item, index) => (
              <Carousel.Item key={index}>
                {item.content}
                {item.mediaInfo && (
                  <div className='position-absolute w-100 bottom-0 bg-body bg-opacity-25 overlay'>
                    <ul className='list-inline small p-0 m-0 lh-l'>
                      <li className='list-inline-item fw-bold'>{item.mediaInfo.Title}</li>
                      <li className='list-inline-item'>by {item.mediaInfo.Artist}</li>
                      <br />
                      <li className='list-inline-item text-muted fw-light small'>{item.mediaInfo.Copyright}</li>
                    </ul>
                  </div>
                )}
              </Carousel.Item>
            ))}
          </Carousel>
        ) : (
          <>
          {postAttachments.map((item, index) => (
            <React.Fragment key={index}>
              {item.content}
              {item.mediaInfo && (
                <div className='position-absolute w-100 bottom-0 bg-body bg-opacity-25 overlay'>
                  <ul className='list-inline small p-0 m-0 lh-l'>
                    <li className='list-inline-item fw-bold'>{item.mediaInfo.Title}</li>
                    <li className='list-inline-item'>by {item.mediaInfo.Artist}</li>
                    <br />
                    <li className='list-inline-item text-muted fw-light small'>{item.mediaInfo.Copyright}</li>
                  </ul>
                </div>
              )}
            </React.Fragment>
          ))}

          </>
        )}  
        {showModal && <PostMediaModal media={selectedMedia} onClose={closeModal} post={post} currentUser={currentUser} />}
      </>
    );
  };