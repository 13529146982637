import React, { useState, useEffect, useContext } from "react";
import { Col, Container, Row, Button, Modal, Form, Spinner, Card, Dropdown } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useUserPreferences } from "../../contexts/UserPreferences";
import { getPostAssociation, getSinglePost, submitPost } from "deso-protocol";
import { DeSoIdentityContext } from "react-deso-protocol";
import { Loader } from "react-bootstrap-typeahead";
import formatPost, { getPosts } from "../../utils/posts";
import { Avatar } from "../../utils/layouts";
import { friendlyFormatDate } from "../../utils/helpers";
import { AccessGroupsContext } from "../../contexts/AccessGroups";
import { localFees } from "../transactions";
import { UserInput } from "../profiles";

const defaultStatuses = {
  'new': { // default (no status assigned)
    'description': 'New ticket confirmed, but must be reviewed by the team.',
    'responsibility': 'team',
    'status': 'new',
  },
  'acknowledged': {
    'description': 'Acknowledged, to be investigated and categorised.',
    'responsibility': 'team',
    'status': 'new',
  },
  'infoneeded': {
    'description': 'More information is needed from the end-user.',
    'responsibility': 'user',
    'status': 'new',
  },
  // Active Tickets
  'documentation': {
    'description': 'Support and documentation needed.',
    'responsibility': 'team',
    'status': 'active',
  },
  'bug': {
    'description': 'Error in the product/service, fix is needed.',
    'responsibility': 'team',
    'status': 'active',
  },
  'improvement': {
    'description': 'Ticket suggests an improvement to the product and will be reviewed.',
    'responsibility': 'team',
    'status': 'active',
  },
  // Completed Tickets
  'duplicate': {
    'description': 'Other tickets of this type already exist. This ticket will be linked to those.',
    'responsibility': 'team',
    'status': 'complete',
  },
  'complete': {
    'description': 'Ticket fully actioned and completed.',
    'responsibility': 'team',
    'status': 'complete',
  },
  'closed': {
    'description': 'Ticket closed - unable to complete or non-relevant.',
    'responsibility': 'team',
    'status': 'complete',
  }
};

export const IssueTrackerPipeline = ({ masterContainer }) => {
  const [ticketCounts, setTicketCounts] = useState(null);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    const fetchTicketCounts = async () => {
      if (!masterContainer) return;

      setLoading(true);

      try {
        const fetchedTickets = await fetchTicketsForMasterContainer(masterContainer);
        setFilteredTickets(fetchedTickets); // Set all tickets as default view
        calculateCounts(fetchedTickets); // Calculate initial counts
      } catch (error) {
        console.error("Error fetching tickets:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTicketCounts();
  }, [masterContainer]);

  const fetchTicketsForMasterContainer = async (masterContainer) => {
    return [
      { id: 1, status: "open", category: "bug" },
      { id: 2, status: "inProgress", category: "improvement" },
      { id: 3, status: "resolved", category: "documentation" },
      { id: 4, status: "closed", category: "bug" },
      { id: 5, status: "underReview", category: "bug" },
      { id: 6, status: "resolved", category: "improvement" },
      { id: 7, status: "inProgress", category: "bug" },
      { id: 8, status: "open", category: "documentation" },
    ];
  };

  const calculateCounts = (tickets) => {
    const counts = {
      open: tickets.filter((ticket) => ticket.status === "open").length,
      inProgress: tickets.filter((ticket) => ticket.status === "inProgress").length,
      underReview: tickets.filter((ticket) => ticket.status === "underReview").length,
      resolved: tickets.filter((ticket) => ticket.status === "resolved").length,
      closed: tickets.filter((ticket) => ticket.status === "closed").length,
      bug: tickets.filter((ticket) => ticket.category === "bug").length,
      improvement: tickets.filter((ticket) => ticket.category === "improvement").length,
      documentation: tickets.filter((ticket) => ticket.category === "documentation").length,
    };
    setTicketCounts(counts);
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    const filtered = category
      ? filteredTickets.filter((ticket) => ticket.category === category)
      : filteredTickets; // Show all tickets if no category selected
    calculateCounts(filtered);
  };

  if (loading) {
    return (
      <div className="text-center my-4">
        <Spinner animation="border" />
        <p>Loading ticket counts...</p>
      </div>
    );
  }

  return (
    <Card className="my-4">
      <Card.Header as="h5">
        {selectedCategory ? `${selectedCategory} ` : ""}Pipeline
        <Dropdown className="float-end">
          <Dropdown.Toggle variant="outline-secondary" id="category-filter">
            {selectedCategory ? selectedCategory : "Filter by Category"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleCategorySelect(null)}>All</Dropdown.Item>
            <Dropdown.Item onClick={() => handleCategorySelect("bug")}>Bug</Dropdown.Item>
            <Dropdown.Item onClick={() => handleCategorySelect("improvement")}>Improvement</Dropdown.Item>
            <Dropdown.Item onClick={() => handleCategorySelect("documentation")}>Documentation</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Card.Header>
      <Card.Body>
        <div className="d-flex flex-row align-items-center text-center mb-4">
          <div className="ms-3">
            <div className="fs-4 text-primary">{ticketCounts.open}</div>
            <small>Open</small>
          </div>
          <div className="flex-grow-1 mx-2 border-top border-primary"></div>
          <div>
            <div className="fs-4 text-warning">{ticketCounts.inProgress}</div>
            <small>In Progress</small>
          </div>
          <div className="flex-grow-1 mx-2 border-top border-secondary"></div>
          <div>
            <div className="fs-4 text-success">{ticketCounts.resolved}</div>
            <small>Resolved</small>
          </div>
          <div className="flex-grow-1 mx-2 border-top border-secondary"></div>
          <div className="me-3">
            <div className="fs-4 text-muted">{ticketCounts.closed}</div>
            <small>Closed</small>
          </div>
        </div>

        <Row className="d-flex align-items-center text-center">
          <Col md={2}>
            <div className="fs-4 text-danger">{ticketCounts.bug}</div>
            <small>Bug</small>
          </Col>
          <Col md={2}>
            <div className="fs-4 text-info">{ticketCounts.improvement}</div>
            <small>Improvement</small>
          </Col>
          <Col md={2}>
            <div className="fs-4 text-warning">{ticketCounts.documentation}</div>
            <small>Documentation</small>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};


export const IssueTrackerIndex = (masterContainer, containers) => {
  const [ ticketsSummary, setTicketsSummary ] = useState(null);
  
  // get master post - which will define everything


  // Count tickets and their statuses


  return (
    <>
      <IssueTrackerPipeline masterContainer={masterContainer} />
    </>
  )
  
};
