import React, { useState, useEffect, useContext } from "react";
import { Col, Container, Row, Button, Modal, Form, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useUserPreferences } from "../contexts/UserPreferences";
import { getPostAssociation, getSinglePost, submitPost } from "deso-protocol";
import { DeSoIdentityContext } from "react-deso-protocol";
import { Loader } from "react-bootstrap-typeahead";
import formatPost, { getPosts } from "../utils/posts";
import { Avatar } from "../utils/layouts";
import { friendlyFormatDate } from "../utils/helpers";
import { AccessGroupsContext } from "../contexts/AccessGroups";
import { localFees } from "./transactions";
import { UserInput } from "./profiles";
import { IssueTrackerIndex } from "./customContainers/customTicketing";

// example definition - goes in PostExtraData (of item.PostFound) in master containers to configure it's elements
// we define at the container level - so that containers can easily be identified through graphQL for instance
// these may be better as PostAssociations so they can be queried on their own endpoint

// GraphQL lookup .vs. GetPostAssociation/s endpoint

// GraphQL NEEDED for any search function

// Search not possible for encrypted content - unless tags/topics, titles or a index field is included (or body used)

const exampleContainer = {
    // Configure this Model
    customContainer: 'type',
    containerType: 'container', // container or node
    masterContainer: null, // null indicates top of tree, else PostHashHex of the master container
    parentContainer: null, // null indicates top level container, else PostHashHex of immediate parent
    // items within a container are considered topics - if posted by a moderator or otherwise allowed
    // Terminology
    nodeLabel: 'category',
    itemLabel: 'topic',
    // Content
    Title: 'Frequently Asked Questions',
    intro: 'Search for support topics covered, or select from the topics below.',
    search: 'Search for support topics...', // set null or false to disable, true or a string (placeholder) to enable search
    subTitle: 'Support topics', // (optional) before the items are displayed
    footerTitle: 'Important', // (optional) before the footer
    footer: 'Please Note - something something something something.', // (optional) if set a footer section is displayed with this text
    
    // Interaction allowed
    //readOnly: true, // no replies allowed from other users - UI controlled
    moderators: [
        'BC1YLjWERF3xWcAD3SeCqtnRwF3FvhoXScZmF5TECd98qeCZpEzgsJD',
        //'BC1YLgSDYkxX8KeYijskhyFFBA4GsgZGQuGvnU9nqLvtzLgwWwxJEAp'
    ],
    encrypted: null, // is this model protected?
    // AccessGroup details... // for the encryption
}
export const UpdateContainer = ({ isLoading, masterContainer, parentContainer, currentItem, PostHashHex }) => {

  console.log("[customContainers.js] UpdateContainer, props: isLoading, masterContainer, currentItem, PostHashHex:", isLoading, masterContainer, currentItem, PostHashHex);

  const { currentUser } = useContext(DeSoIdentityContext);
  const { preferences } = useUserPreferences();
  const userLocale = navigator.language || navigator.userLanguage;

  const [isEdit, setIsEdit] = useState(null);
  const isTopLevel = !isEdit || (currentItem?.PostFound?.PostExtraData?.masterContainer === null || currentItem?.PostFound?.PostExtraData?.masterContainer === currentItem?.PostFound?.PostHashHex);
  const isItem = Boolean(currentItem?.PostFound?.PostExtraData?.masterContainer && !currentItem?.PostFound?.PostExtraData?.containerType);

  const [isCreating, setIsCreating] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [newItemData, setNewItemData] = useState({});
  const [modalType, setModalType] = useState(null);
  const navigate = useNavigate();
  const [moderatorSearchTerm, setModeratorSearchTerm] = useState('');

  useEffect(() => {
    console.log('[customContainers.js] newItemData updated: ',newItemData);
  }, [newItemData]);

  let itemName = masterContainer?.PostFound?.PostExtraData?.itemName || null;
  if(!itemName && masterContainer?.PostFound?.PostExtraData?.containerType) {
    switch(masterContainer?.PostFound?.PostExtraData?.containerType) {
      case "ticketing":
        itemName = 'ticket';
      break;

      case "forum":
        itemName = 'discussion';
      break;

      default:
        itemName = 'item';
      break;
    }
  }

  const initializeFormData = (isEdit, isTopLevel, isItem, item) => {
      const postExtraData =  isEdit ? item?.PostFound?.PostExtraData : {};

      const basePostExtraData = {
          Node: preferences?.nodeId?.toString(),
          Language: userLocale,
          masterContainer: postExtraData?.masterContainer || masterContainer?.PostFound.PostHashHex,
          parentContainer: postExtraData?.parentContainer || parentContainer?.PostFound.PostHashHex,
          Title:  postExtraData?.Title || '',
      };

      const additionalPostExtraData = {};

      if (isTopLevel && !isItem) {
          if (postExtraData?.containerType) additionalPostExtraData.containerType = postExtraData.containerType;
          if (postExtraData?.intro) additionalPostExtraData.intro = postExtraData.intro;
          if (postExtraData?.search) additionalPostExtraData.search = postExtraData.search;
          if (postExtraData?.subTitle) additionalPostExtraData.subTitle = postExtraData.subTitle;
          if (postExtraData?.footer) additionalPostExtraData.footer = postExtraData.footer;
      }

      setNewItemData({
          UpdaterPublicKeyBase58Check: currentUser?.PublicKeyBase58Check,
          BodyObj: {
              Body: isEdit ? currentItem?.PostFound?.Body || '' : '',
              ImageURLs: isEdit ? currentItem?.PostFound?.ImageURLs || [] : [],
              VideoURLs: isEdit ? currentItem?.PostFound?.VideoURLs || [] : [],
          },
          PostExtraData: {
              ...basePostExtraData,
              ...additionalPostExtraData,
          },
          ParentStakeID: PostHashHex || null,
          PostHashHexToModify: isEdit ? currentItem?.PostFound?.PostHashHex : null,
          TransactionFees: [localFees()],
      });
  };

  const updateNestedData = (field, value) => {
      setNewItemData((prevState) => ({
          ...prevState,
          PostExtraData: {
              ...prevState.PostExtraData,
              [field]: value,
          },
      }));
  };

  const addModerator = (publicKey) => {
    setNewItemData(prevState => ({
      ...prevState,
      PostExtraData: {
        ...prevState.PostExtraData,
        moderators: [...(prevState.PostExtraData.moderators || []), publicKey]
      }
    }));
  };
  
  const removeModerator = (publicKey) => {
    setNewItemData(prevState => ({
      ...prevState,
      PostExtraData: {
        ...prevState.PostExtraData,
        moderators: prevState.PostExtraData.moderators.filter(moderator => moderator !== publicKey)
      }
    }));
  };
  
  

  const toggleCreateModal = (type, item) => {
    switch (type) {
      case "model":
        setIsEdit(true);
        initializeFormData(true, true, false, masterContainer);
        setModalType('model');
        break;
      case "addContainer":
        setIsEdit(false);
        initializeFormData(false, false, false, null);
        setModalType('container');
        break;
      case "editContainer":
        setIsEdit(true);
        initializeFormData(true, false, false, item);
        setModalType('container');
        break;
      case "addItem":
        setIsEdit(false);
        initializeFormData(false, false, true);
        setModalType('item');
        break;
      case "editItem":
        setIsEdit(true);
        initializeFormData(true, false, true, item);
        setModalType('item');
        break;
    }

    //initializeFormData(isEdit, isTopLevel, isItem);
    setIsCreating(true);
  };

  const handleSave = async () => {
      setIsSaving(true);
      try {
          const submitPayload = newItemData;
          if(submitPayload.PostExtraData.moderators) { submitPayload.PostExtraData.moderators = JSON.stringify(submitPayload.PostExtraData.moderators); }
          if(submitPayload.PostExtraData.enableFooter) { submitPayload.PostExtraData.enableFooter = JSON.stringify(submitPayload.PostExtraData.enableFooter); }
          if(submitPayload.PostExtraData.enableSearch) { submitPayload.PostExtraData.enableSearch = JSON.stringify(submitPayload.PostExtraData.enableSearch); }
          const response = await submitPost(submitPayload);
          setIsSaving(false);
          setIsCreating(false);
          if(response.PostHashHex) { navigate(`/posts/${response.PostHashHex}`); }
      } catch (error) {
          console.error("Error creating post:", error);
          setIsSaving(false);
      }
  };

  return (
      <>
          {!isLoading && currentItem ? (
              <>
                <div className="d-flex flex-row justify-content-between">
                    <span className="me-auto"></span>
                    {currentUser &&
                        ((currentItem?.PostFound?.PostExtraData?.moderators?.includes(currentUser.PublicKeyBase58Check)) ||
                            currentUser?.PublicKeyBase58Check === currentItem?.PostFound?.PosterPublicKeyBase58Check) ? (
                            <>
                              {currentItem === masterContainer ? (
                                <>
                                {currentUser?.PublicKeyBase58Check === currentItem?.PostFound?.PosterPublicKeyBase58Check && (
                                  <Button variant="secondary ms-3" onClick={() => toggleCreateModal('model')}>
                                    <i className="bi bi-bounding-box me-2"></i>
                                      Configure Model
                                  </Button>
                                )}

                                <Button variant="secondary ms-3" onClick={() => toggleCreateModal('addContainer')}>
                                    <i className="bi bi-folder-fill me-2"></i>
                                    Add Sub-category
                                </Button>
                                <Button variant="info ms-3" onClick={() => toggleCreateModal('addItem')}>
                                    <i className="bi bi-plus-lg me-2"></i>
                                    New {itemName}
                                </Button>
                                </>
                              ) : (
                                <Button size={'sm'} variant="outline mt-auto ms-auto" onClick={() => toggleCreateModal(currentItem?.PostFound?.PostExtraData?.containerType ? 'editContainer' : `editItem`,currentItem)}>
                                    <i className="bi bi-pencil-square me-2"></i>
                                    Edit
                                </Button>
                              )}
                            </>
                        ) : (
                            currentUser && PostHashHex && !(currentItem?.PostFound?.PostExtraData?.readOnly) ? (
                                <Button variant="info ms-3" onClick={() => toggleCreateModal('addItem')}>
                                    <i className="bi bi-plus-lg me-2"></i>
                                    New {itemName}
                                </Button>
                            ) : (
                                currentUser && !PostHashHex && (
                                    <>Creation mode
                                        <Button variant="secondary ms-3" onClick={() => toggleCreateModal('model')}>
                                            <i className="bi bi-bounding-box me-2"></i> Create custom content
                                        </Button>
                                    </>
                                )
                            )
                        )
                    }
                </div>

                  <Modal show={isCreating} onHide={() => setIsCreating(false)}>
                      <Modal.Header closeButton>
                          <Modal.Title>
                            {modalType === 'model' ? (
                              'Configure Model'
                            ) : (
                              `${isEdit ? 'Edit ' : 'New '} ${modalType === 'container' ? 'Sub-category' : itemName }`
                            )}
                          </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                          <Form>
                            <>
                              <Form.Group className="mb-3" controlId="formTitle">
                                  <Form.Label>Title</Form.Label>
                                  <Form.Control
                                      type="text"
                                      placeholder="Enter title"
                                      value={newItemData.PostExtraData?.Title || ''}
                                      onChange={(e) => updateNestedData('Title', e.target.value)}
                                  />
                              </Form.Group>

                              {isTopLevel && modalType === 'model' && (
                              <>

                                <Form.Group className="mb-3" controlId="formType">
                                  <Form.Label>Model</Form.Label>
                                  <Form.Select
                                    value={newItemData.PostExtraData?.containerType || ''}
                                    onChange={(e) => updateNestedData('containerType', e.target.value)}
                                  >
                                    <option value="">Select Model Type</option>
                                    <option value="forum">Forum</option>
                                    <option value="ticketing">Issue Tracker</option>
                                    <option value="category">Category</option>
                                    <option value="folder">Folder</option>
                                    <option value="product">Product</option>
                                  </Form.Select>
                                </Form.Group>

                                {/* Read Only Toggle */}
                                <Form.Group className="mb-3" controlId="toggleReadOnly">
                                  <Form.Check
                                    type="switch"
                                    id="readOnly"
                                    label="Read Only"
                                    checked={!!newItemData.PostExtraData?.readOnly}
                                    onChange={(e) => updateNestedData('readOnly', e.target.checked)}
                                  />
                                </Form.Group>

                                {/* Member Only Toggle */}
                                <Form.Group className="mb-3" controlId="toggleMemberOnly">
                                  <Form.Check
                                    type="switch"
                                    id="memberOnly"
                                    label="Member Only"
                                    checked={!!newItemData.PostExtraData?.memberOnly}
                                    onChange={(e) => updateNestedData('memberOnly', e.target.checked)}
                                  />
                                </Form.Group>

                                {/* Moderators Input */}
                                <Form.Group className="mb-3" controlId="formModerators">
                                  <Form.Label>Moderators</Form.Label>
                                  <UserInput
                                    idName="moderatorUserInput"
                                    searchTerm={moderatorSearchTerm}
                                    setSearchTerm={setModeratorSearchTerm}
                                    onSelect={(selectedUser)=> addModerator(selectedUser.target.PublicKeyBase58Check)}
                                    /*onSelect={(selectedUser) => {
                                      // Add selected moderator to the array
                                      console.log(selectedUser.target.PublicKeyBase58Check,selectedUser);
                                      const updatedModerators = [
                                        ...(newItemData.PostExtraData?.moderators || []),
                                        selectedUser.target.PublicKeyBase58Check,
                                      ];
                                      updateNestedData('moderators', updatedModerators);
                                    }}*/
                                    name="moderators"
                                    classes="form-control"
                                    placeholder="Search for moderators"
                                    search={true}
                                  />
                                  {/* Display selected moderators */}
                                  {newItemData.PostExtraData?.moderators && newItemData.PostExtraData?.moderators.length > 0 && (
                                    <div className="d-flex flex-row align-items-center small mt-1">
                                      <span className="text-muted fw-bold">Moderators:</span>
                                    {newItemData.PostExtraData?.moderators?.map((publicKey, index) => (
                                      <div
                                      className="d-flex flex-shrink-1 bg-secondary bg-gradient-10 rounded-pill m-1 px-1"
                                      style={{ padding: "0.05em" }}
                                      key={publicKey}  // Use publicKey as the key
                                    >
                                      <div className="d-flex flex-shrink-1 align-items-center text-truncate">
                                        <Avatar publicKey={publicKey} type="avatar" />
                                        <Avatar publicKey={publicKey} type="username" />
                                      </div>
                                      <button
                                        className="btn flex-shrink-1 align-items-center p-0"
                                        name="deletePoster"
                                        value={publicKey}
                                        onClick={() => removeModerator(publicKey)}
                                      >
                                        <i className="bi bi-x-circle-fill"></i>
                                      </button>
                                    </div>
                                    ))}
                                    </div>
                                  )}
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formIntro">
                                  <Form.Label>Introduction</Form.Label>
                                  <Form.Control
                                    placeholder="Enter an introduction"
                                    as="textarea"
                                    rows={3}
                                    value={newItemData.PostExtraData?.intro || ''}
                                    onChange={(e) => updateNestedData('intro', e.target.value)}
                                  />
                                </Form.Group>

                                {/* Toggle for Enable Search */}
                                <Form.Group className="mb-3" controlId="toggleSearch">
                                  <Form.Check
                                    type="switch"
                                    id="enableSearch"
                                    label="Enable Search"
                                    checked={!!newItemData.PostExtraData?.enableSearch}
                                    onChange={(e) => updateNestedData('enableSearch', e.target.checked)}
                                  />
                                </Form.Group>

                                {/* Conditionally show search input if enabled */}
                                {newItemData.PostExtraData?.enableSearch && (
                                  <Form.Group className="mb-3" controlId="formSearch">
                                    <Form.Label>Search Placeholder</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Search placeholder text"
                                      value={newItemData.PostExtraData?.search || ''}
                                      onChange={(e) => updateNestedData('search', e.target.value)}
                                    />
                                  </Form.Group>
                                )}

                                <Form.Group className="mb-3" controlId="formSubTitle">
                                  <Form.Label>SubTitle</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter subtitle"
                                    value={newItemData.PostExtraData?.subTitle || ''}
                                    onChange={(e) => updateNestedData('subTitle', e.target.value)}
                                  />
                                </Form.Group>

                                {/* Toggle for Footer */}
                                <Form.Group className="mb-3" controlId="toggleFooter">
                                  <Form.Check
                                    type="switch"
                                    id="enableFooter"
                                    label="Enable Footer"
                                    checked={!!newItemData.PostExtraData?.enableFooter}
                                    onChange={(e) => updateNestedData('enableFooter', e.target.checked)}
                                  />
                                </Form.Group>

                                {/* Conditionally show footer input if enabled */}
                                {newItemData.PostExtraData?.enableFooter && (
                                  <Form.Group className="mb-3" controlId="formFooter">
                                    <Form.Label>Footer</Form.Label>
                                    <Form.Control
                                      as="textarea"
                                      rows={3}
                                      placeholder="Footer content"
                                      value={newItemData.PostExtraData?.footer || ''}
                                      onChange={(e) => updateNestedData('footer', e.target.value)}
                                    />
                                  </Form.Group>
                                )}
                              </>
                            )}


                              <Form.Group className="mb-3" controlId="formBody">
                                  <Form.Label>Body</Form.Label>
                                  <Form.Control
                                      as="textarea"
                                      rows={5}
                                      placeholder="Enter content or description"
                                      value={newItemData.BodyObj?.Body || ''}
                                      onChange={(e) =>
                                          setNewItemData((prevState) => ({
                                              ...prevState,
                                              BodyObj: {
                                                  ...prevState.BodyObj,
                                                  Body: e.target.value,
                                              },
                                          }))
                                      }
                                  />
                              </Form.Group>
                            </>
                          </Form>
                          { JSON.stringify(newItemData, null, 2) }
                      </Modal.Body>
                      <Modal.Footer>
                          <Button variant="secondary" onClick={() => setIsCreating(false)}>
                              Close
                          </Button>
                          <Button variant="primary" onClick={handleSave} disabled={isSaving}>
                              {isSaving ? 'Saving...' : 'Save'}
                          </Button>
                      </Modal.Footer>
                  </Modal>
              </>
          ) : (
              <Spinner animation="border" />
          )}
      </>
  );
};




export const CustomContainer = ({ postHash }) => {
    const {currentUser, alternateUsers} = useContext(DeSoIdentityContext);
    const {accessGroups} = useContext(AccessGroupsContext);
    const {preferences} = useUserPreferences();
    const [ PostHashHex, setPostHashHex ] = useState(postHash ? postHash : null);
    const [ masterContainer, setMasterContainer] = useState(null);
    const [ parentContainer, setParentContainer] = useState(null);
    const [ currentItem, setCurrentItem] = useState(null);
    const [ containers, setContainers] = useState(null);
    const [ items, setItems] = useState(null);
    const [ results, setResults ] = useState(null);
    const [ contentCount, setContentCount ] = useState({});
    const [ isLoading, setIsLoading ] = useState(true);
    const [ isLoadingResults, setIsLoadingResults ] = useState(true);
    const [ refresh, setRefresh ] = useState(true);
    const [ searchTerm, setSearchTerm ] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [debounceTimeout, setDebounceTimeout] = useState(null);

    /// for preview
    const [ formattedPost, setFormattedPost ] = useState(null);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const data = await getSinglePost({ PostHashHex: PostHashHex, FetchParents: true });
          console.log("<CustomContainer> Data:", data);
          //formatPost(post, currentUser, level = 0, type = 'post', view = 'list', accessGroups = null, preferences, thread, preview, alternateUsers, isExpanded) {
          //const tmp = await formatPost(exampleStructure.PostFound, currentUser, 0, 'post', 'list', null, null, false);
          const tmp = await formatPost(data.PostFound, currentUser, 0, 'post', 'list', null, null, false);
          console.log("formatted post: ",tmp);

          if(data.PostFound.PostExtraData?.masterContainer !== masterContainer?.PostFound?.PostHashHex || data.PostFound.PostExtraData?.containerType) {
            if(data.PostFound.PostExtraData?.masterContainer === data.PostFound.PostHashHex || !data.PostFound.PostExtraData?.masterContainer) {
              // Is the master container
              setMasterContainer(data)
            } else {
              // Load the master container too
              const masterData = await getSinglePost({ PostHashHex: data.PostFound.PostExtraData?.masterContainer });
              setMasterContainer(masterData);
            } 
          }

          if(data.PostFound.PostExtraData?.parentContainer !== parentContainer?.PostFound?.PostHashHex) {
            if(data.PostFound.PostExtraData?.parentContainer === data.PostFound.PostHashHex || !data.PostFound.PostExtraData?.parentContainer) {
              // Is the master container
              setParentContainer(data)
            } else {
              // Load the master container too
              const parentData = await getSinglePost({ PostHashHex: data.PostFound.PostExtraData?.parentContainer });
              setParentContainer(parentData);
            }
          }

          setIsLoading(false);
          setIsLoadingResults(false);
          setRefresh(false);
          setCurrentItem(data);
          setFormattedPost(tmp);
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoading(false);
          setRefresh(false);
        }
      };
  
      if(PostHashHex !== currentItem?.PostFound?.PostHashHex) {
        fetchData();
        setIsLoadingResults(true);
      } else if (refresh) {
        setIsLoading(true);
        fetchData();
      } else if(!PostHashHex) {
        setIsLoading(false);
      }
    }, [PostHashHex, refresh]);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const variables = {
            first: 10,
            filter: {
              and: [
                /*{
                  parentPostHash: {
                    equalTo: PostHashHex
                  }
                },*/
                {
                  postHash: {
                    notEqualTo: PostHashHex
                  }
                },
                {
                  extraData: {
                    contains: { "masterContainer": PostHashHex, }
                  }
                },
                searchTerm && searchTerm !== ''
                  ? {
                      or: [
                        {
                          body: {
                            includesInsensitive: searchTerm
                          }
                        },
                        {
                          extraData: {
                            contains: searchTerm
                          }
                        }
                      ]
                    }
                  : null
              ].filter(Boolean) // Removes `null` entries from the array
            }
          };
          const data = await getPosts(variables, currentUser, preferences);
          console.log("<CustomContainer> Results:", data);
          setResults(data);
          setItems(null);
          setContainers(null); 
          setIsLoadingResults(false);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoading(true);
          setIsLoadingResults(false);
          setRefresh(false);
        }
      };
  
      if (refresh || searchTerm || currentItem) {
        setIsLoadingResults(true);
        fetchData();
      }
    }, [currentItem, searchTerm, refresh]);

    useEffect(() => {
      if (results) {
        const processResults = () => {
          const tmpContainers = [];
          const tmpItems = [];
    
          results.nodes.forEach(node => {
            const contentType = node.PostFound.PostExtraData?.containerType;
            console.log("Containers, Items:", node);
    
            if (contentType) {
              // It's a container
              tmpContainers.push(node);
            } else {
              // It's an item
              tmpItems.push(node);
            }
          });
    
          // Assuming you have setContainers and setItems as state setters
          setContainers(tmpContainers);
          setItems(tmpItems);
          console.log("Containers, Items:", tmpContainers, tmpItems);
        };
  
        processResults();
      }
    }, [results]);
    
  
    const toggleRefresh = () => {
      setCurrentItem(null);
      setRefresh(true);
    };
  
    const handleChange = (e) => {
      const value = e.target.value;
      setInputValue(value);
  
      // Clear previous timeout
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
  
      // Set new timeout for 3 seconds delay
      const timeout = setTimeout(() => {
        setSearchTerm(value);  // Set the actual search term after delay
      }, 1250);
  
      setDebounceTimeout(timeout);
    };
  
    const clearInput = () => {
      setInputValue('');
      setSearchTerm('');
    };

    if(isLoading) { return (
      <div className="h-100 d-flex flex-column p-0 m-0 justify-content-center">
        <div className='text-center'><Spinner /></div>
      </div>
     ); }

    if(masterContainer?.PostFound?.PostExtraData?.containerType === 'ticketing') {
      return <IssueTrackerIndex masterContainer={masterContainer} containers={containers} />
    }
    return (
      <>
            {masterContainer && masterContainer.PostFound ? (
            <>
              <Container>
                <div className="d-flex flex-row align-content-end text-end">
                  <span className="flex-fill"></span>
                  <button className="btn btn-outline-secondary flex-shrink-1" onClick={toggleRefresh}><i className="bi bi-arrow-clockwise me-2"></i>Refresh</button>
                </div>
              </Container>
              <Container key={{PostHashHex}} className='border rounded-3 pt-3 my-3'>
                <Row className='mb-3'>
                  <div className="d-flex flex-row justify-content-between">
                    <div><h3>{ masterContainer.PostFound.PostExtraData.Title }</h3></div>
                    <span className="d-flex flex-column text-end"> 
                      <span className="d-flex flex-row flex-nowrap justify-content-end">
                        <Avatar publicKey={masterContainer.PostFound.PosterPublicKeyBase58Check} type="avatar" />
                        <Avatar publicKey={masterContainer.PostFound.PosterPublicKeyBase58Check} type="username" />
                      </span>
                      <span className="text-muted small me-2">{friendlyFormatDate((masterContainer.PostFound.TimestampNanos/1000000))}</span>
                      { currentUser && ((masterContainer.PostFound.PostExtraData.moderators && masterContainer.PostFound.PostExtraData.moderators.includes(currentUser.PublicKeyBase58Check)) || (currentUser === masterContainer.PostFound.RepostedPostEntryResponse )) && (
                        <span className="text-muted small me-2"><i className="bi bi-pencil-square me-2"></i>Configure model</span>
                      ) }
                    </span>
                  </div>
                </Row>
                <Row className="mb-3">
                  <Col>{ masterContainer.PostFound.PostExtraData.intro }</Col>
                </Row>
                {!currentItem && masterContainer.PostFound.PostExtraData.search && (
                  <Row className='text-center mb-3'>
                    <Col xs={12} md={8} lg={6} className="offset-md-2 offset-lg-3">
                      <div className="input-group">
                        <input 
                          className="form-control bg-background border-secondary" 
                          id="searchFormValue"
                          type="search" 
                          name="search" 
                          aria-label="Search value" 
                          placeholder={typeof masterContainer.PostFound.PostExtraData.search === 'string' 
                            ? masterContainer.PostFound.PostExtraData.search 
                            : "Search for a help topic..."}
                          onChange={handleChange} 
                          value={inputValue}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
  
                {masterContainer.PostFound.PostExtraData.subTitle && (
                  <Row className="mb-3"><h5>{masterContainer.PostFound.PostExtraData.subTitle}</h5></Row>
                )}

                <Row className='text-muted text-small'>
                  <Col>
                    <ul class="list-inline">
                    {currentItem && currentItem?.PostFound?.ParentPosts?.slice().reverse().map((parent, index) => (
                      <li class="list-inline-item me-3" key={index}>
                        <span className='action' onClick={() => setPostHashHex(parent.PostHashHex)}>
                          {parent.PostHashHex === masterContainer.PostFound.PostHashHex && ( <i className="bi bi-house-fill me-3"></i> )}
                          {parent.PostExtraData?.Title || 'Untitled'}
                        </span>
                        <i className="bi bi-chevron-double-right ms-3 small"></i>
                      </li>
                    ))}
                    <li class="list-inline-item action disabled">
                      {currentItem && currentItem.PostFound.PostHashHex === masterContainer.PostFound.PostHashHex && ( <i className="bi bi-house-fill me-3"></i> )}
                      {currentItem && currentItem.PostFound.PostExtraData.Title}
                    </li>
                    </ul>
                  </Col>
                </Row>


                { isLoadingResults || !currentItem ? (
                  <Row className='h-100'>
                    <Col className='text-center'><Spinner /></Col>
                  </Row>
                ) : (
                  <>  
                    {currentItem !== masterContainer && !currentItem.PostFound.PostExtraData.containerType ? (
                      <>
                        <OutputItem masterContainer={masterContainer} parentContainer={parentContainer} currentItem={currentItem} setPostHashHex={setPostHashHex} searchTerm={searchTerm} currentUser={currentUser} preferences={preferences} />
                      </>
                    ) : (
                      <>
                        {!results && (
                          <Row className=''>
                            <Col xs={12} className="text-center">
                              <div className="card bg-secondary bg-opacity-25 p-3 rounded mb-3">
                                <div className="fw-bold">Sorry, no topics found</div>
                              </div>
                            </Col>
                        </Row>
                        )}
                      </>
                    )}
                    {currentItem?.PostFound?.PostExtraData?.containerType && (
                      <>
                        <OutputContainer masterContainer={masterContainer} parentContainer={parentContainer} items={containers} setPostHashHex={setPostHashHex} />
                        <OutputContainer masterContainer={masterContainer} parentContainer={parentContainer} items={`header`} setPostHashHex={setPostHashHex} />
                        <OutputContainer masterContainer={masterContainer} parentContainer={parentContainer} items={items} setPostHashHex={setPostHashHex} />
                      </>
                    )}
                  </>
                )}

                {masterContainer.PostFound.PostExtraData.footer && (
                  <Row className="mb-3">
                    <Col>
                      { masterContainer.PostFound.PostExtraData.footerTitle && (
                        <h5>{masterContainer.PostFound.PostExtraData.footerTitle}</h5>
                      )}
                      { masterContainer.PostFound.PostExtraData.footer }
                    </Col>
                  </Row>
                )}
                { currentUser && (
                  <Row className="mb-3">
                    <UpdateContainer isLoading={isLoading} masterContainer={masterContainer} parentContainer={parentContainer} currentItem={currentItem} PostHashHex={PostHashHex} />
                  </Row>
                )}
              </Container>
              {formattedPost && ( 
                <Col xs={6}>
                {formattedPost}
                </Col>
             )}
            </>
            ) : (
              <Container>
                <h5>Custom Content Creation</h5>
                <UpdateContainer />
              </Container>
            )}
      </>
    );
};


export const truncatePreview = (text, limit = 25) => {
  if (!text || text?.length <= limit) {
    return text;
  }
  // Trim to the closest word
  const trimmedText = text.substr(0, text.lastIndexOf(' ', limit));
  return trimmedText + "...";
};

export const OutputContainer = ({ masterContainer, parentContainer, items, setPostHashHex }) => {

  const containerType = masterContainer.PostFound.PostExtraData.containerType || null;

  if(items === 'header') {
    switch(containerType) {
      case "forum":
        return (
          <div className='row' key={`items`}>
              <Col
                xs={12}
                className={`d-flex mb-4`}
              >
                <div
                  className={`card bg-opacity-25 rounded w-100`}
                >
                  <div className="card-body">
                    <Row className="align-items-center">
                      <div className="col-9 col-md-10 col-lg-5 d-flex flex-column fw-bold">
                        Description
                      </div>
                      <div className="col-3 col-md-6 col-lg-2 fw-bold">
                        Author
                      </div>
                      <div className="col-6 col-md-6 col-lg-1 fw-light fw-lg-bold text-lg-center">Threads</div>
                      <div className="col-6 col-md-6 col-lg-1 text-lg-center">Replies</div>
                      <div className="col-12 col-md-6 col-lg-2">
                        Last reply
                      </div>
                    </Row>
                  </div>
                </div>
              </Col>
          </div>
        )
      break;
    }
  }

  switch(containerType) {
    case "forum": // forum / BB layout
      // for forums we also want to fetch info like the last post, number of items etc.
      return (
        <>
        {items && items.length > 0 && (
          <div className='row' key={`items`}>
            {items.map((item, index) => (
              <Col
                xs={12}
                key={item?.PostFound.PostHashHex}
                className={`d-flex mb-4 ${item?.PostFound?.PostExtraData?.containerType ? 'order-first' : 'order-last'}`}
              >
                <div
                  className={`card position-relative ${item?.PostFound?.PostExtraData?.containerType ? 'bg-info' : 'bg-secondary'} bg-opacity-25 rounded w-100`}
                >
                  <div className={`card-body ${item?.PostFound?.PostExtraData?.containerType ? 'py-2' : ''} action`} onClick={() => setPostHashHex(item?.PostFound?.PostHashHex)}>
                    <Row className="align-items-center">
                      <div className="col-12 col-md-10 col-lg-5 d-flex flex-column">
                        <span className="d-flex flex-row flex-nowrap">
                          {item?.PostFound?.PostExtraData?.containerType ? (
                            <>
                              <i className="bi bi-folder-fill me-2"></i>
                              {/*<span className="d-none d-md-block text-truncate">Forum</span>*/}
                            </>
                          ) : (
                            <>
                              <i className="bi bi-body-text me-2"></i>
                              {/*<span className="d-none d-md-block text-truncate">Topic</span>*/}
                            </>
                          )}
                          {item?.PostFound.PostExtraData?.Title || 'Example topic title'}
                        </span>
                        <small>{truncatePreview(item?.PostFound.Body || '', 80)}</small>
                      </div>
                      {!item?.PostFound?.PostExtraData?.containerType && (
                        <>
                        <div className="col-9 col-md-6 col-lg-2 text-muted small">
                          {item && (

                                <span className="d-flex flex-column">
                                  <span className="d-flex align-items-center">
                                    <Avatar publicKey={item?.PostFound.PosterPublicKeyBase58Check} type="avatar" />
                                    <Avatar publicKey={item?.PostFound.PosterPublicKeyBase58Check} type="username" />
                                  </span>
                                  <span className="text-muted small"><i className="bi bi-vector-pen me-1"></i>{new Date(item.PostFound.TimestampNanos/1000000).toLocaleString()}</span>
                                </span>
                          )}
                        </div>
                        <div className="col-3 col-lg-1 text-center">threads</div>
                        <div className="col-3 col-lg-1 text-center">{item?.PostFound.CommentCount}</div>
                        <div className="col-9 col-md-6 col-lg-2 text-muted small">
                          {item && (
                                <span className="d-flex flex-column">
                                  <span className="d-flex align-items-center">
                                    <Avatar publicKey={item?.PostFound.PosterPublicKeyBase58Check} type="avatar" />
                                    <Avatar publicKey={item?.PostFound.PosterPublicKeyBase58Check} type="username" />
                                  </span>
                                  <span className="text-muted small"><i className="bi bi-chat-right-text-fill me-1"></i>dd/mm/yyyy, hh:mm:ss</span>
                                </span>
                              
                          )}
                        </div>
                        </>
                      )}
                    </Row>
                  </div>
                  <div className="position-absolute end-0 bottom-0">
                        <UpdateContainer
                          masterContainer={masterContainer}
                          parentContainer={parentContainer}
                          currentItem={item}
                          PostHashHex={item?.PostFound.PostHashHex}
                        />
                  </div>
                </div>
              </Col>
          ))}
          </div>
        )}
      </>
      );
    break;

    default: // standard folder/category-content model
      return (
        <>
        {Array.isArray(items) && items.length > 0 && (
          <div className="row" key={`items`}>
            {items.map((item, index) => (
              <Col
                xs={12}
                md={6}
                lg={4}
                key={item?.PostFound.PostHashHex}
                className={`d-flex mb-4 ${item?.PostFound?.PostExtraData?.containerType ? 'order-first' : 'order-last'}`}
              >
                <div
                  className={`card ${item?.PostFound?.PostExtraData?.containerType ? 'bg-tertiary' : 'bg-secondary'} bg-opacity-25 rounded w-100 h-100 d-flex flex-column justify-content-evenly`}
                >
                  <div className="card-body d-flex flex-column justify-content-between h-100 p-3">
                    <div className="fw-bold">
                      {item?.PostFound.PostExtraData?.containerType ? (
                        <i className="bi bi-folder-fill me-2"></i>
                      ) : (
                        <i className="bi bi-body-text me-2"></i>
                      )}
                      {item?.PostFound.PostExtraData?.Title || 'Example topic title'}
                    </div>
                    <small>{truncatePreview(item?.PostFound.Body || '', 80)}</small>
                    {!item?.PostFound?.PostExtraData?.containerType && (
                      <span className="mt-1 pb-0 d-flex flex-row small flex-nowrap align-items-center justify-content-end">
                        <Avatar publicKey={item?.PostFound.PosterPublicKeyBase58Check} type="avatar" />
                        <Avatar publicKey={item?.PostFound.PosterPublicKeyBase58Check} type="username" />
                        <UpdateContainer
                          masterContainer={masterContainer}
                          parentContainer={parentContainer}
                          currentItem={item}
                          PostHashHex={item?.PostFound.PostHashHex}
                        />
                      </span>
                    )}
                  </div>
                </div>
              </Col>
            ))}
          </div>
        )}
        </>
      );
    break;
  }
};

export const OutputItem = ({ masterContainer, parentContainer, currentItem, setPostHashHex, searchTerm, currentUser, preferences }) => {
  const containerType = masterContainer.PostFound.PostExtraData.containerType || null;
  const [ results, setResults ] = useState(null);
  const [ isLoadingResults, setIsLoadingResults ] = useState(true);
  const [ refresh, setRefresh ] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const variables = {
          first: 10,
          filter: {
            and: [
              /*{
                parentPostHash: {
                  equalTo: PostHashHex
                }
              },*/
              {
                parentPostHash: {
                  equalTo: currentItem.PostFound.PostHashHex
                }
              },
              searchTerm && searchTerm !== ''
                ? {
                    or: [
                      {
                        body: {
                          includesInsensitive: searchTerm
                        }
                      },
                      {
                        extraData: {
                          contains: searchTerm
                        }
                      }
                    ]
                  }
                : null
            ].filter(Boolean) // Removes `null` entries from the array
          }
        };
        const data = await getPosts(variables, currentUser, preferences);
        console.log("<CustomContainer> item's resultsOf :", data);
        setResults(data);
        setIsLoadingResults(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoadingResults(false);
        setRefresh(false);
      }
    };

    if (refresh || searchTerm || currentItem) {
      setIsLoadingResults(true);
      fetchData();
    }
  }, [currentItem, searchTerm, refresh]);


  switch(containerType) {
    case "forum": // forum / BB layout
      // for forums we also want to fetch info like the last post, number of currentItems etc.
      return (
        <>
          <Row className="border-bottom p-3">
            <div className={`col-12 col-lg-12`}><h3>{currentItem.PostFound.PostExtraData.Title}</h3></div>
            <div className={`col-12 col-lg-2`}>
              <span className="d-flex flex-column">
                <span className="d-flex align-items-center mb-2">
                  <Avatar publicKey={currentItem?.PostFound.PosterPublicKeyBase58Check} type="avatar" />
                  <Avatar publicKey={currentItem?.PostFound.PosterPublicKeyBase58Check} type="username" />
                </span>
                <span className="text-muted small">{new Date(currentItem.PostFound.TimestampNanos/1000000).toLocaleString()}</span>
              </span>
            </div>
            <div className={`col-12 col-lg-10`}>
              {currentItem.PostFound.Body}  
            </div>
          </Row>
          {results && !isLoadingResults ? (
            <>
            {results && results?.nodes.length !== 0 && (
              <>
              <Row className="border-bottom p-3">
                <div className="col">Discussion</div>
              </Row>
              {results?.nodes.map((item, index) => (
              <Row className="border-bottom p-3" key={index}>
                <div className={`col-12 col-lg-2`}>
                  <span className="d-flex flex-column">
                    <span className="d-flex align-items-center mb-2">
                      <Avatar publicKey={item?.PostFound.PosterPublicKeyBase58Check} type="avatar" />
                      <Avatar publicKey={item?.PostFound.PosterPublicKeyBase58Check} type="username" />
                    </span>
                    <span className="text-muted small">{new Date(item.PostFound.TimestampNanos/1000000).toLocaleString()}</span>
                  </span>
                </div>
                <div className={`col-12 col-lg-10`}>
                  {item.PostFound.Body}  
                </div>
              </Row>
              ))}
              </>
            )}
            {results.hasNextPage || results.hasPreviousPage ? (
              <Row className="border-bottom p-3">
                <div className="col">Navigation | Next / Last page etc.</div>
              </Row>
            ) : (
              <Row className="border-bottom p-3">
                <div className="col">no more results</div>
              </Row>
            )}
            </>
          ) : (
            <Loader />
          )}
          <Row className="border-bottom p-3">
            <div className="col">
              <h6>Discuss</h6>
              <Form.Group className="mb-3" controlId="formBody">
                <Form.Label className="visually-hidden">Enter a reply</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder={`${results && results?.nodes.length !== 0 ? `Reply to thread...` : `Be the first to reply`}`}
                />
            </Form.Group>
            </div>
          </Row>
        </>
      );
    break;

    default: // standard folder/category-content model
      return (
        <Row className="mb-3">
          <h3>{currentItem.PostFound.PostExtraData.Title}</h3>
          {currentItem.PostFound.Body}
        </Row>
      );
    break;
  }
};


// This is the example post object that will contain the container definition in it's PostExtraData.
const exampleStructure = {
PostFound: {
"PostHashHex": "f605278e89d4f5f8f244fc87618816f85cefa6325e2943703c10df01fb4aef28",
"PosterPublicKeyBase58Check": "BC1YLjWERF3xWcAD3SeCqtnRwF3FvhoXScZmF5TECd98qeCZpEzgsJD",
"ParentStakeID": "",
Body: "This is an example model.\nClick xyz to view this model.",
ImageURLs: ["https://images.deso.org/0db7e9097f8f930c2027334674ae712f2e3723629fef6a401c1883e5b0de4c22.webp"],
"VideoURLs": [],
"RepostedPostEntryResponse": null,
"CreatorBasisPoints": 1000,
"StakeMultipleBasisPoints": 12500,
"TimestampNanos": 1725488400271395000,
"IsHidden": false,
"ConfirmationBlockHeight": 3398026,
"InMempool": false,
"ProfileEntryResponse": {
    "PublicKeyBase58Check": "BC1YLjWERF3xWcAD3SeCqtnRwF3FvhoXScZmF5TECd98qeCZpEzgsJD",
    "Username": "StarGeezer",
    "Description": "Simple astronomy - made easy.\n🔭 Astronomy 📷 Photography 🌌 Space\n👷‍♂️ Building community together 💖\n© Images own work unless credited\n\nSubscribe for alerts with @NoteMe:\n  👉 https://noteme.icu/subscribe/StarGeezer\n\nBeginner's astronomy guides: \n  👉 https://www.stargeezer.co.uk\n\n💡 High founder reward to support upgrade fund and community activities.\n\n🔭 Telescope Upgrade Fund - dedicated astrocamera 📷 \n🎯 🟩🟩🟩🟩🟩🟩⬛⬛⬛⬛ 109/180 Ð (60%)\nFR, 💎s, NFTs and transfers to @StarGeezer_UpgradeFund",
    "IsHidden": false,
    "IsReserved": false,
    "IsVerified": true,
    "Comments": null,
    "Posts": null,
    "CoinEntry": {
        "CreatorBasisPoints": 8000,
        "DeSoLockedNanos": 154262127394,
        "NumberOfHolders": 444,
        "CoinsInCirculationNanos": 53118132858,
        "CoinWatermarkNanos": 60694516273,
        "BitCloutLockedNanos": 154262127394
    },
    "DAOCoinEntry": {
        "NumberOfHolders": 0,
        "CoinsInCirculationNanos": "0x0",
        "MintingDisabled": false,
        "TransferRestrictionStatus": "unrestricted",
        "LockupTransferRestrictionStatus": "unrestricted"
    },
    "CoinPriceDeSoNanos": 8712400145,
    "CoinPriceBitCloutNanos": 8712400145,
    "UsersThatHODL": null,
    "IsFeaturedTutorialWellKnownCreator": false,
    "IsFeaturedTutorialUpAndComingCreator": false,
    "ExtraData": {
        "DAOPublicKeysPurchased": "BC1YLhmjqvA2BAZYmpcGNuoyFpAkRU9fZGC55t8jgq4Gvy9uLAn7p5a",
        "FeaturedImageURL": "https://images.deso.org/688bdc879d0e325a1666874f4eed3c4b263fcef4183d29b45480c8c738d9c57f.webp",
        "LargeProfilePicURL": "",
        "LargeProfilePicUrl": ""
    },
    "DESOBalanceNanos": 2540552530,
    "BestExchangeRateDESOPerDAOCoin": 0
},
"Comments": null,
"LikeCount": 0,
"DiamondCount": 55,
"PostEntryReaderState": {
    "LikedByReader": false,
    "DiamondLevelBestowed": 0,
    "RepostedByReader": false,
    "RepostPostHashHex": ""
},
"IsPinned": false,
"PostExtraData": {
    "Language": "en-US",
    "Node": "11",
    "app": "DeSocialWorld",
    ...exampleContainer
},
"CommentCount": 13,
"RepostCount": 4,
"QuoteRepostCount": 4,
"ParentPosts": [],
"IsNFT": false,
"IsFrozen": false,
"NumNFTCopies": 0,
"NumNFTCopiesForSale": 0,
"NumNFTCopiesBurned": 0,
"HasUnlockable": false,
"NFTRoyaltyToCreatorBasisPoints": 0,
"NFTRoyaltyToCoinBasisPoints": 0,
"AdditionalDESORoyaltiesMap": {},
"AdditionalCoinRoyaltiesMap": {},
"DiamondsFromSender": 0,
"HotnessScore": 0,
"PostMultiplier": 0,
"RecloutCount": 4,
"QuoteRecloutCount": 4,
"RecloutedPostEntryResponse": null
}
/*PostFound: {
    PosterPublicKeyBase58Check: 'BC1YLjWERF3xWcAD3SeCqtnRwF3FvhoXScZmF5TECd98qeCZpEzgsJD',
    Body: 'This is an example model. Click xyz to view this model.',
    ImageURLs: ["https://images.deso.org/0db7e9097f8f930c2027334674ae712f2e3723629fef6a401c1883e5b0de4c22.webp"],
    PostExtraData: {
    customContainer: 'type',
    title: 'Example Title',
    intro: 'Example introduction',
    footer: 'Example footer',
    readOnly: true,
    }
}
*/
}