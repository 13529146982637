import React, { createContext, useState, useEffect, useContext } from 'react';
import { DeSoIdentityContext } from "react-deso-protocol";
import { checkPartyAccessGroups, getAllAccessGroups, getAllMessageThreads, getPaginatedAccessGroupMembers } from 'deso-protocol';
import { addProfilesToCache } from '../services/profileCache';

export const AccessGroupsContext = createContext();

export const AccessGroupsProvider = ({ children }) => {
  const { currentUser } = useContext(DeSoIdentityContext);
  const [accessGroups, setAccessGroups] = useState(null);
  const [chatThreads, setChatThreads] = useState([]); // Initialize as an array
  const [isLoading, setIsLoading] = useState(true);
  const [showModalChat, setShowModalChat] = useState(false);
  const [selectedThread, setSelectedThread] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const triggerRefreshAccessGroups = () => {
    fetchAccessGroups();
  }

  const fetchAccessGroups = async () => {
    try {
      if (currentUser) {
        const groups = await getAllAccessGroups({ PublicKeyBase58Check: currentUser.PublicKeyBase58Check });  
        setAccessGroups(groups);
        //console.log('[AccessGroups.jsx] Retrieved AccessGroups:', groups);
        fetchChatData();
      }
    } catch (error) {
      console.error('[AccessGroups.jsx] Error fetching access groups:', error);
    }
  };

  const fetchChatData = async () => {
    if (!currentUser || !accessGroups) return;
    try {
      const messageThreads = await getAllMessageThreads({ UserPublicKeyBase58Check: currentUser.PublicKeyBase58Check });
      accessGroups.Members = [];
      //console.log("[AccessGroups.jsx] Getting threads.... messageThreads: ", messageThreads);
      if (accessGroups.AccessGroupsMember) {
        for (const accessGroupMember of accessGroups.AccessGroupsMember) {
          const paginatedMembers = await getPaginatedAccessGroupMembers({
            AccessGroupOwnerPublicKeyBase58Check: accessGroupMember.AccessGroupOwnerPublicKeyBase58Check,
            AccessGroupKeyName: accessGroupMember.AccessGroupKeyName,
            MaxMembersToFetch: 20
          });
          addProfilesToCache(paginatedMembers.PublicKeyToProfileEntryResponse);
          accessGroups.Members[accessGroupMember.AccessGroupKeyName] = paginatedMembers.AccessGroupMembersBase58Check;
        }
      }
      setChatThreads(messageThreads || []); // Ensure chatThreads is set as an array
      console.log('[AccessGroups.jsx] Retrieved Threads:', messageThreads);
      addProfilesToCache(messageThreads.PublicKeyToProfileEntryResponse);
      setIsInitialLoad(false); 
    } catch (error) {
      console.error('[AccessGroups.jsx] Error fetching chat data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setAccessGroups(null);
    setChatThreads([]);
    fetchAccessGroups();
  }, [currentUser]);

  useEffect(() => {
    const pollingInterval = setInterval(fetchChatData, 25000);
    return () => {
      clearInterval(pollingInterval);
    };
  }, [currentUser, accessGroups]);

  /* - original working for DMs but no Groups
  const initiateChat = async (ReceiverPublicKeyBase58Check, isGroupChat = false) => {
    const existingThread = chatThreads?.MessageThreads?.find(thread => 
      thread.ChatType === 'DM' &&
      (thread.SenderInfo.OwnerPublicKeyBase58Check === ReceiverPublicKeyBase58Check ||
      thread.RecipientInfo.OwnerPublicKeyBase58Check === ReceiverPublicKeyBase58Check)
    );
  
    if (existingThread) {
      setSelectedThread(existingThread);
    } else {
      const request = {
        "SenderAccessGroupKeyName": "default-key",
        "SenderPublicKeyBase58Check": currentUser.PublicKeyBase58Check,
        "RecipientAccessGroupKeyName": "default-key",
        "RecipientPublicKeyBase58Check": ReceiverPublicKeyBase58Check,
      };
  
      try {
        const partyAccessGroups = await checkPartyAccessGroups(request);
        const newThread = {
          "ChatType": "DM",
          "SenderInfo": {
            "OwnerPublicKeyBase58Check": partyAccessGroups.SenderPublicKeyBase58Check,
            "AccessGroupPublicKeyBase58Check": partyAccessGroups.SenderAccessGroupPublicKeyBase58Check,
            "AccessGroupKeyName": partyAccessGroups.SenderAccessGroupKeyName,
          },
          "RecipientInfo": {
            "OwnerPublicKeyBase58Check": partyAccessGroups.RecipientPublicKeyBase58Check,
            "AccessGroupPublicKeyBase58Check": partyAccessGroups.RecipientAccessGroupPublicKeyBase58Check,
            "AccessGroupKeyName": partyAccessGroups.RecipientAccessGroupKeyName,
          },
          "NewThread": true,
        };
        setChatThreads(prevThreads => ({
          ...prevThreads,
          MessageThreads: [...prevThreads.MessageThreads, newThread]
        }));
        setSelectedThread(newThread);
      } catch (error) {
        console.error("Error checking party access groups:", error);
      }
    }
  
    setShowModalChat(true);
  };
  */
  const initiateChat = async (ReceiverPublicKeyBase58Check, isGroupChat = false) => {
    // Wait until chatThreads are loaded if it's the initial load
    if (isInitialLoad) {
      await new Promise(resolve => {
        const checkInterval = setInterval(() => {
          if (!isInitialLoad) {
            clearInterval(checkInterval);
            resolve();
          }
        }, 100); // Check every 100ms
      });
    }
  
    let existingThread;
  
    if (isGroupChat) {
      // For GroupChat, find the thread by AccessGroupPublicKeyBase58Check
      existingThread = chatThreads?.MessageThreads?.find(thread =>
        thread.ChatType === 'GroupChat' &&
        thread.RecipientInfo.AccessGroupPublicKeyBase58Check === ReceiverPublicKeyBase58Check
      );
    } else {
      // For DM, find the thread by checking Sender and Recipient Public Keys
      existingThread = chatThreads?.MessageThreads?.find(thread => 
        thread.ChatType === 'DM' &&
        (thread.SenderInfo.OwnerPublicKeyBase58Check === ReceiverPublicKeyBase58Check ||
        thread.RecipientInfo.OwnerPublicKeyBase58Check === ReceiverPublicKeyBase58Check)
      );
    }
  
    if (existingThread) {
      setSelectedThread(existingThread);
    } else {
      const request = {
        "SenderAccessGroupKeyName": "default-key",
        "SenderPublicKeyBase58Check": currentUser.PublicKeyBase58Check,
        "RecipientAccessGroupKeyName": "default-key",
        "RecipientPublicKeyBase58Check": ReceiverPublicKeyBase58Check,
      };
  
      try {
        const partyAccessGroups = await checkPartyAccessGroups(request);
        const newThread = {
          "ChatType": isGroupChat ? 'GroupChat' : 'DM', // Set ChatType based on isGroupChat
          "SenderInfo": {
            "OwnerPublicKeyBase58Check": partyAccessGroups.SenderPublicKeyBase58Check,
            "AccessGroupPublicKeyBase58Check": partyAccessGroups.SenderAccessGroupPublicKeyBase58Check,
            "AccessGroupKeyName": partyAccessGroups.SenderAccessGroupKeyName,
          },
          "RecipientInfo": isGroupChat
            ? { // GroupChat has AccessGroupPublicKeyBase58Check
                "AccessGroupPublicKeyBase58Check": partyAccessGroups.RecipientAccessGroupPublicKeyBase58Check,
                "AccessGroupKeyName": partyAccessGroups.RecipientAccessGroupKeyName,
              }
            : { // DM doesn't use AccessGroupPublicKeyBase58Check
                "OwnerPublicKeyBase58Check": partyAccessGroups.RecipientPublicKeyBase58Check,
                "AccessGroupPublicKeyBase58Check": partyAccessGroups.RecipientAccessGroupPublicKeyBase58Check,
                "AccessGroupKeyName": partyAccessGroups.RecipientAccessGroupKeyName,
              },
          "NewThread": true,
        };
        setChatThreads(prevThreads => ({
          ...prevThreads,
          MessageThreads: [...(prevThreads.MessageThreads || []), newThread]
        }));
        setSelectedThread(newThread);
      } catch (error) {
        console.error("Error checking party access groups:", error);
      }
    }
  
    setShowModalChat(true);
  };
  

  return (
    <AccessGroupsContext.Provider value={{ accessGroups, chatThreads, isLoading, showModalChat, setShowModalChat, initiateChat, selectedThread, setSelectedThread, triggerRefreshAccessGroups }}>
      {children}
    </AccessGroupsContext.Provider>
  );
};
